import $ol$AssertionError from './ol/AssertionError';
import {CollectionEvent as _ol_Collection$CollectionEvent} from './ol/Collection';
import $ol$Collection from './ol/Collection';
import $ol$Disposable from './ol/Disposable';
import $ol$Feature from './ol/Feature';
import {createStyleFunction as _ol_Feature$createStyleFunction} from './ol/Feature';
import $ol$Geolocation from './ol/Geolocation';
import $ol$Image from './ol/Image';
import {listenImage as _ol_Image$listenImage} from './ol/Image';
import $ol$ImageBase from './ol/ImageBase';
import $ol$ImageCanvas from './ol/ImageCanvas';
import $ol$ImageTile from './ol/ImageTile';
import $ol$Kinetic from './ol/Kinetic';
import $ol$Map from './ol/Map';
import $ol$MapBrowserEvent from './ol/MapBrowserEvent';
import $ol$MapBrowserEventHandler from './ol/MapBrowserEventHandler';
import $ol$MapEvent from './ol/MapEvent';
import {ObjectEvent as _ol_Object$ObjectEvent} from './ol/Object';
import $ol$Object from './ol/Object';
import {getChangeEventType as _ol_Object$getChangeEventType} from './ol/Object';
import $ol$Observable from './ol/Observable';
import {unByKey as _ol_Observable$unByKey} from './ol/Observable';
import $ol$Overlay from './ol/Overlay';
import $ol$PluggableMap from './ol/PluggableMap';
import $ol$Tile from './ol/Tile';
import $ol$TileCache from './ol/TileCache';
import $ol$TileQueue from './ol/TileQueue';
import {getTilePriority as _ol_TileQueue$getTilePriority} from './ol/TileQueue';
import $ol$TileRange from './ol/TileRange';
import {createOrUpdate as _ol_TileRange$createOrUpdate} from './ol/TileRange';
import $ol$VectorRenderTile from './ol/VectorRenderTile';
import $ol$VectorTile from './ol/VectorTile';
import $ol$View from './ol/View';
import {createCenterConstraint as _ol_View$createCenterConstraint} from './ol/View';
import {createResolutionConstraint as _ol_View$createResolutionConstraint} from './ol/View';
import {createRotationConstraint as _ol_View$createRotationConstraint} from './ol/View';
import {isNoopAnimation as _ol_View$isNoopAnimation} from './ol/View';
import {binarySearch as _ol_array$binarySearch} from './ol/array';
import {numberSafeCompareFunction as _ol_array$numberSafeCompareFunction} from './ol/array';
import {includes as _ol_array$includes} from './ol/array';
import {linearFindNearest as _ol_array$linearFindNearest} from './ol/array';
import {reverseSubArray as _ol_array$reverseSubArray} from './ol/array';
import {extend as _ol_array$extend} from './ol/array';
import {remove as _ol_array$remove} from './ol/array';
import {find as _ol_array$find} from './ol/array';
import {equals as _ol_array$equals} from './ol/array';
import {stableSort as _ol_array$stableSort} from './ol/array';
import {findIndex as _ol_array$findIndex} from './ol/array';
import {isSorted as _ol_array$isSorted} from './ol/array';
import {assert as _ol_asserts$assert} from './ol/asserts';
import {createExtent as _ol_centerconstraint$createExtent} from './ol/centerconstraint';
import {none as _ol_centerconstraint$none} from './ol/centerconstraint';
import $ol$color from './ol/color';
import {asString as _ol_color$asString} from './ol/color';
import {fromString as _ol_color$fromString} from './ol/color';
import {asArray as _ol_color$asArray} from './ol/color';
import {normalize as _ol_color$normalize} from './ol/color';
import {toString as _ol_color$toString} from './ol/color';
import {isStringColor as _ol_color$isStringColor} from './ol/color';
import {asColorLike as _ol_colorlike$asColorLike} from './ol/colorlike';
import {defaults as _ol_control$defaults} from './ol/control';
import {add as _ol_coordinate$add} from './ol/coordinate';
import {closestOnCircle as _ol_coordinate$closestOnCircle} from './ol/coordinate';
import {closestOnSegment as _ol_coordinate$closestOnSegment} from './ol/coordinate';
import {createStringXY as _ol_coordinate$createStringXY} from './ol/coordinate';
import {degreesToStringHDMS as _ol_coordinate$degreesToStringHDMS} from './ol/coordinate';
import {format as _ol_coordinate$format} from './ol/coordinate';
import {equals as _ol_coordinate$equals} from './ol/coordinate';
import {rotate as _ol_coordinate$rotate} from './ol/coordinate';
import {scale as _ol_coordinate$scale} from './ol/coordinate';
import {squaredDistance as _ol_coordinate$squaredDistance} from './ol/coordinate';
import {distance as _ol_coordinate$distance} from './ol/coordinate';
import {squaredDistanceToSegment as _ol_coordinate$squaredDistanceToSegment} from './ol/coordinate';
import {toStringHDMS as _ol_coordinate$toStringHDMS} from './ol/coordinate';
import {toStringXY as _ol_coordinate$toStringXY} from './ol/coordinate';
import {wrapX as _ol_coordinate$wrapX} from './ol/coordinate';
import {getWorldsAway as _ol_coordinate$getWorldsAway} from './ol/coordinate';
import {CLASS_HIDDEN as _ol_css$CLASS_HIDDEN} from './ol/css';
import $ol$css from './ol/css';
import {CLASS_SELECTABLE as _ol_css$CLASS_SELECTABLE} from './ol/css';
import {CLASS_UNSELECTABLE as _ol_css$CLASS_UNSELECTABLE} from './ol/css';
import {CLASS_UNSUPPORTED as _ol_css$CLASS_UNSUPPORTED} from './ol/css';
import {CLASS_CONTROL as _ol_css$CLASS_CONTROL} from './ol/css';
import {CLASS_COLLAPSED as _ol_css$CLASS_COLLAPSED} from './ol/css';
import {getFontParameters as _ol_css$getFontParameters} from './ol/css';
import {createCanvasContext2D as _ol_dom$createCanvasContext2D} from './ol/dom';
import {outerWidth as _ol_dom$outerWidth} from './ol/dom';
import {outerHeight as _ol_dom$outerHeight} from './ol/dom';
import {replaceNode as _ol_dom$replaceNode} from './ol/dom';
import {removeNode as _ol_dom$removeNode} from './ol/dom';
import {removeChildren as _ol_dom$removeChildren} from './ol/dom';
import {replaceChildren as _ol_dom$replaceChildren} from './ol/dom';
import {easeIn as _ol_easing$easeIn} from './ol/easing';
import {easeOut as _ol_easing$easeOut} from './ol/easing';
import {inAndOut as _ol_easing$inAndOut} from './ol/easing';
import {linear as _ol_easing$linear} from './ol/easing';
import {upAndDown as _ol_easing$upAndDown} from './ol/easing';
import {listen as _ol_events$listen} from './ol/events';
import $ol$events from './ol/events';
import {listenOnce as _ol_events$listenOnce} from './ol/events';
import {unlistenByKey as _ol_events$unlistenByKey} from './ol/events';
import {boundingExtent as _ol_extent$boundingExtent} from './ol/extent';
import $ol$extent from './ol/extent';
import {buffer as _ol_extent$buffer} from './ol/extent';
import {clone as _ol_extent$clone} from './ol/extent';
import {closestSquaredDistanceXY as _ol_extent$closestSquaredDistanceXY} from './ol/extent';
import {containsCoordinate as _ol_extent$containsCoordinate} from './ol/extent';
import {containsExtent as _ol_extent$containsExtent} from './ol/extent';
import {containsXY as _ol_extent$containsXY} from './ol/extent';
import {coordinateRelationship as _ol_extent$coordinateRelationship} from './ol/extent';
import {createEmpty as _ol_extent$createEmpty} from './ol/extent';
import {createOrUpdate as _ol_extent$createOrUpdate} from './ol/extent';
import {createOrUpdateEmpty as _ol_extent$createOrUpdateEmpty} from './ol/extent';
import {createOrUpdateFromCoordinate as _ol_extent$createOrUpdateFromCoordinate} from './ol/extent';
import {createOrUpdateFromCoordinates as _ol_extent$createOrUpdateFromCoordinates} from './ol/extent';
import {createOrUpdateFromFlatCoordinates as _ol_extent$createOrUpdateFromFlatCoordinates} from './ol/extent';
import {createOrUpdateFromRings as _ol_extent$createOrUpdateFromRings} from './ol/extent';
import {equals as _ol_extent$equals} from './ol/extent';
import {approximatelyEquals as _ol_extent$approximatelyEquals} from './ol/extent';
import {extend as _ol_extent$extend} from './ol/extent';
import {extendCoordinate as _ol_extent$extendCoordinate} from './ol/extent';
import {extendCoordinates as _ol_extent$extendCoordinates} from './ol/extent';
import {extendFlatCoordinates as _ol_extent$extendFlatCoordinates} from './ol/extent';
import {extendRings as _ol_extent$extendRings} from './ol/extent';
import {extendXY as _ol_extent$extendXY} from './ol/extent';
import {forEachCorner as _ol_extent$forEachCorner} from './ol/extent';
import {getArea as _ol_extent$getArea} from './ol/extent';
import {getBottomLeft as _ol_extent$getBottomLeft} from './ol/extent';
import {getBottomRight as _ol_extent$getBottomRight} from './ol/extent';
import {getCenter as _ol_extent$getCenter} from './ol/extent';
import {getCorner as _ol_extent$getCorner} from './ol/extent';
import {getEnlargedArea as _ol_extent$getEnlargedArea} from './ol/extent';
import {getForViewAndSize as _ol_extent$getForViewAndSize} from './ol/extent';
import {getHeight as _ol_extent$getHeight} from './ol/extent';
import {getIntersectionArea as _ol_extent$getIntersectionArea} from './ol/extent';
import {getIntersection as _ol_extent$getIntersection} from './ol/extent';
import {getMargin as _ol_extent$getMargin} from './ol/extent';
import {getSize as _ol_extent$getSize} from './ol/extent';
import {getTopLeft as _ol_extent$getTopLeft} from './ol/extent';
import {getTopRight as _ol_extent$getTopRight} from './ol/extent';
import {getWidth as _ol_extent$getWidth} from './ol/extent';
import {intersects as _ol_extent$intersects} from './ol/extent';
import {isEmpty as _ol_extent$isEmpty} from './ol/extent';
import {returnOrUpdate as _ol_extent$returnOrUpdate} from './ol/extent';
import {scaleFromCenter as _ol_extent$scaleFromCenter} from './ol/extent';
import {intersectsSegment as _ol_extent$intersectsSegment} from './ol/extent';
import {applyTransform as _ol_extent$applyTransform} from './ol/extent';
import {wrapX as _ol_extent$wrapX} from './ol/extent';
import {loadFeaturesXhr as _ol_featureloader$loadFeaturesXhr} from './ol/featureloader';
import {xhr as _ol_featureloader$xhr} from './ol/featureloader';
import {setWithCredentials as _ol_featureloader$setWithCredentials} from './ol/featureloader';
import {TRUE as _ol_functions$TRUE} from './ol/functions';
import {FALSE as _ol_functions$FALSE} from './ol/functions';
import {VOID as _ol_functions$VOID} from './ol/functions';
import {memoizeOne as _ol_functions$memoizeOne} from './ol/functions';
import $ol$has from './ol/has';
import {FIREFOX as _ol_has$FIREFOX} from './ol/has';
import {SAFARI as _ol_has$SAFARI} from './ol/has';
import {WEBKIT as _ol_has$WEBKIT} from './ol/has';
import {MAC as _ol_has$MAC} from './ol/has';
import {DEVICE_PIXEL_RATIO as _ol_has$DEVICE_PIXEL_RATIO} from './ol/has';
import {WORKER_OFFSCREEN_CANVAS as _ol_has$WORKER_OFFSCREEN_CANVAS} from './ol/has';
import {IMAGE_DECODE as _ol_has$IMAGE_DECODE} from './ol/has';
import {PASSIVE_EVENT_LISTENERS as _ol_has$PASSIVE_EVENT_LISTENERS} from './ol/has';
import {defaults as _ol_interaction$defaults} from './ol/interaction';
import {all as _ol_loadingstrategy$all} from './ol/loadingstrategy';
import {bbox as _ol_loadingstrategy$bbox} from './ol/loadingstrategy';
import {tile as _ol_loadingstrategy$tile} from './ol/loadingstrategy';
import {clamp as _ol_math$clamp} from './ol/math';
import {cosh as _ol_math$cosh} from './ol/math';
import $ol$math from './ol/math';
import {log2 as _ol_math$log2} from './ol/math';
import {squaredSegmentDistance as _ol_math$squaredSegmentDistance} from './ol/math';
import {squaredDistance as _ol_math$squaredDistance} from './ol/math';
import {solveLinearSystem as _ol_math$solveLinearSystem} from './ol/math';
import {toDegrees as _ol_math$toDegrees} from './ol/math';
import {toRadians as _ol_math$toRadians} from './ol/math';
import {modulo as _ol_math$modulo} from './ol/math';
import {lerp as _ol_math$lerp} from './ol/math';
import {jsonp as _ol_net$jsonp} from './ol/net';
import $ol$net from './ol/net';
import {assign as _ol_obj$assign} from './ol/obj';
import $ol$obj from './ol/obj';
import {clear as _ol_obj$clear} from './ol/obj';
import {getValues as _ol_obj$getValues} from './ol/obj';
import {isEmpty as _ol_obj$isEmpty} from './ol/obj';
import {cloneTransform as _ol_proj$cloneTransform} from './ol/proj';
import {identityTransform as _ol_proj$identityTransform} from './ol/proj';
import {addProjection as _ol_proj$addProjection} from './ol/proj';
import {addProjections as _ol_proj$addProjections} from './ol/proj';
import {get as _ol_proj$get} from './ol/proj';
import {getPointResolution as _ol_proj$getPointResolution} from './ol/proj';
import {addEquivalentProjections as _ol_proj$addEquivalentProjections} from './ol/proj';
import {addEquivalentTransforms as _ol_proj$addEquivalentTransforms} from './ol/proj';
import {clearAllProjections as _ol_proj$clearAllProjections} from './ol/proj';
import {createProjection as _ol_proj$createProjection} from './ol/proj';
import {createTransformFromCoordinateTransform as _ol_proj$createTransformFromCoordinateTransform} from './ol/proj';
import {addCoordinateTransforms as _ol_proj$addCoordinateTransforms} from './ol/proj';
import {fromLonLat as _ol_proj$fromLonLat} from './ol/proj';
import {toLonLat as _ol_proj$toLonLat} from './ol/proj';
import {equivalent as _ol_proj$equivalent} from './ol/proj';
import {getTransformFromProjections as _ol_proj$getTransformFromProjections} from './ol/proj';
import {getTransform as _ol_proj$getTransform} from './ol/proj';
import {transform as _ol_proj$transform} from './ol/proj';
import {transformExtent as _ol_proj$transformExtent} from './ol/proj';
import {transformWithProjections as _ol_proj$transformWithProjections} from './ol/proj';
import {setUserProjection as _ol_proj$setUserProjection} from './ol/proj';
import {clearUserProjection as _ol_proj$clearUserProjection} from './ol/proj';
import {getUserProjection as _ol_proj$getUserProjection} from './ol/proj';
import {useGeographic as _ol_proj$useGeographic} from './ol/proj';
import {toUserCoordinate as _ol_proj$toUserCoordinate} from './ol/proj';
import {fromUserCoordinate as _ol_proj$fromUserCoordinate} from './ol/proj';
import {toUserExtent as _ol_proj$toUserExtent} from './ol/proj';
import {fromUserExtent as _ol_proj$fromUserExtent} from './ol/proj';
import {createSafeCoordinateTransform as _ol_proj$createSafeCoordinateTransform} from './ol/proj';
import {addCommon as _ol_proj$addCommon} from './ol/proj';
import {toContext as _ol_render$toContext} from './ol/render';
import {getVectorContext as _ol_render$getVectorContext} from './ol/render';
import {getRenderPixel as _ol_render$getRenderPixel} from './ol/render';
import $ol$reproj from './ol/reproj';
import {calculateSourceResolution as _ol_reproj$calculateSourceResolution} from './ol/reproj';
import {calculateSourceExtentResolution as _ol_reproj$calculateSourceExtentResolution} from './ol/reproj';
import {render as _ol_reproj$render} from './ol/reproj';
import $ol$resolutionconstraint from './ol/resolutionconstraint';
import {createSnapToResolutions as _ol_resolutionconstraint$createSnapToResolutions} from './ol/resolutionconstraint';
import {createSnapToPower as _ol_resolutionconstraint$createSnapToPower} from './ol/resolutionconstraint';
import {createMinMaxResolution as _ol_resolutionconstraint$createMinMaxResolution} from './ol/resolutionconstraint';
import {disable as _ol_rotationconstraint$disable} from './ol/rotationconstraint';
import {none as _ol_rotationconstraint$none} from './ol/rotationconstraint';
import {createSnapToN as _ol_rotationconstraint$createSnapToN} from './ol/rotationconstraint';
import {createSnapToZero as _ol_rotationconstraint$createSnapToZero} from './ol/rotationconstraint';
import {buffer as _ol_size$buffer} from './ol/size';
import {hasArea as _ol_size$hasArea} from './ol/size';
import {scale as _ol_size$scale} from './ol/size';
import {toSize as _ol_size$toSize} from './ol/size';
import {DEFAULT_RADIUS as _ol_sphere$DEFAULT_RADIUS} from './ol/sphere';
import $ol$sphere from './ol/sphere';
import {getDistance as _ol_sphere$getDistance} from './ol/sphere';
import {getLength as _ol_sphere$getLength} from './ol/sphere';
import {getArea as _ol_sphere$getArea} from './ol/sphere';
import {offset as _ol_sphere$offset} from './ol/sphere';
import {padNumber as _ol_string$padNumber} from './ol/string';
import {compareVersions as _ol_string$compareVersions} from './ol/string';
import {createOrUpdate as _ol_tilecoord$createOrUpdate} from './ol/tilecoord';
import {getKeyZXY as _ol_tilecoord$getKeyZXY} from './ol/tilecoord';
import {getKey as _ol_tilecoord$getKey} from './ol/tilecoord';
import {fromKey as _ol_tilecoord$fromKey} from './ol/tilecoord';
import {hash as _ol_tilecoord$hash} from './ol/tilecoord';
import {withinExtentAndZ as _ol_tilecoord$withinExtentAndZ} from './ol/tilecoord';
import {getForProjection as _ol_tilegrid$getForProjection} from './ol/tilegrid';
import {wrapX as _ol_tilegrid$wrapX} from './ol/tilegrid';
import {createForExtent as _ol_tilegrid$createForExtent} from './ol/tilegrid';
import {createXYZ as _ol_tilegrid$createXYZ} from './ol/tilegrid';
import $ol$tilegrid from './ol/tilegrid';
import {createForProjection as _ol_tilegrid$createForProjection} from './ol/tilegrid';
import {extentFromProjection as _ol_tilegrid$extentFromProjection} from './ol/tilegrid';
import {createFromTemplate as _ol_tileurlfunction$createFromTemplate} from './ol/tileurlfunction';
import {createFromTemplates as _ol_tileurlfunction$createFromTemplates} from './ol/tileurlfunction';
import {createFromTileUrlFunctions as _ol_tileurlfunction$createFromTileUrlFunctions} from './ol/tileurlfunction';
import {nullTileUrlFunction as _ol_tileurlfunction$nullTileUrlFunction} from './ol/tileurlfunction';
import {expandUrl as _ol_tileurlfunction$expandUrl} from './ol/tileurlfunction';
import $ol$transform from './ol/transform';
import {create as _ol_transform$create} from './ol/transform';
import {reset as _ol_transform$reset} from './ol/transform';
import {multiply as _ol_transform$multiply} from './ol/transform';
import {set as _ol_transform$set} from './ol/transform';
import {setFromArray as _ol_transform$setFromArray} from './ol/transform';
import {apply as _ol_transform$apply} from './ol/transform';
import {rotate as _ol_transform$rotate} from './ol/transform';
import {scale as _ol_transform$scale} from './ol/transform';
import {makeScale as _ol_transform$makeScale} from './ol/transform';
import {translate as _ol_transform$translate} from './ol/transform';
import {compose as _ol_transform$compose} from './ol/transform';
import {composeCssTransform as _ol_transform$composeCssTransform} from './ol/transform';
import {invert as _ol_transform$invert} from './ol/transform';
import {makeInverse as _ol_transform$makeInverse} from './ol/transform';
import {determinant as _ol_transform$determinant} from './ol/transform';
import {toString as _ol_transform$toString} from './ol/transform';
import {appendParams as _ol_uri$appendParams} from './ol/uri';
import {abstract as _ol_util$abstract} from './ol/util';
import {getUid as _ol_util$getUid} from './ol/util';
import {VERSION as _ol_util$VERSION} from './ol/util';
import $ol$util from './ol/util';
import {ARRAY_BUFFER as _ol_webgl$ARRAY_BUFFER} from './ol/webgl';
import $ol$webgl from './ol/webgl';
import {ELEMENT_ARRAY_BUFFER as _ol_webgl$ELEMENT_ARRAY_BUFFER} from './ol/webgl';
import {STREAM_DRAW as _ol_webgl$STREAM_DRAW} from './ol/webgl';
import {STATIC_DRAW as _ol_webgl$STATIC_DRAW} from './ol/webgl';
import {DYNAMIC_DRAW as _ol_webgl$DYNAMIC_DRAW} from './ol/webgl';
import {UNSIGNED_BYTE as _ol_webgl$UNSIGNED_BYTE} from './ol/webgl';
import {UNSIGNED_SHORT as _ol_webgl$UNSIGNED_SHORT} from './ol/webgl';
import {UNSIGNED_INT as _ol_webgl$UNSIGNED_INT} from './ol/webgl';
import {FLOAT as _ol_webgl$FLOAT} from './ol/webgl';
import {getContext as _ol_webgl$getContext} from './ol/webgl';
import {getSupportedExtensions as _ol_webgl$getSupportedExtensions} from './ol/webgl';
import {XML_SCHEMA_INSTANCE_URI as _ol_xml$XML_SCHEMA_INSTANCE_URI} from './ol/xml';
import $ol$xml from './ol/xml';
import {createElementNS as _ol_xml$createElementNS} from './ol/xml';
import {getAllTextContent as _ol_xml$getAllTextContent} from './ol/xml';
import {getAllTextContent_ as _ol_xml$getAllTextContent_} from './ol/xml';
import {isDocument as _ol_xml$isDocument} from './ol/xml';
import {getAttributeNS as _ol_xml$getAttributeNS} from './ol/xml';
import {parse as _ol_xml$parse} from './ol/xml';
import {makeArrayExtender as _ol_xml$makeArrayExtender} from './ol/xml';
import {makeArrayPusher as _ol_xml$makeArrayPusher} from './ol/xml';
import {makeReplacer as _ol_xml$makeReplacer} from './ol/xml';
import {makeObjectPropertyPusher as _ol_xml$makeObjectPropertyPusher} from './ol/xml';
import {makeObjectPropertySetter as _ol_xml$makeObjectPropertySetter} from './ol/xml';
import {makeChildAppender as _ol_xml$makeChildAppender} from './ol/xml';
import {makeArraySerializer as _ol_xml$makeArraySerializer} from './ol/xml';
import {makeSimpleNodeFactory as _ol_xml$makeSimpleNodeFactory} from './ol/xml';
import {OBJECT_PROPERTY_NODE_FACTORY as _ol_xml$OBJECT_PROPERTY_NODE_FACTORY} from './ol/xml';
import {makeSequence as _ol_xml$makeSequence} from './ol/xml';
import {makeStructureNS as _ol_xml$makeStructureNS} from './ol/xml';
import {parseNode as _ol_xml$parseNode} from './ol/xml';
import {pushParseAndPop as _ol_xml$pushParseAndPop} from './ol/xml';
import {serialize as _ol_xml$serialize} from './ol/xml';
import {pushSerializeAndPop as _ol_xml$pushSerializeAndPop} from './ol/xml';
import {registerXMLSerializer as _ol_xml$registerXMLSerializer} from './ol/xml';
import {getXMLSerializer as _ol_xml$getXMLSerializer} from './ol/xml';
import {registerDocument as _ol_xml$registerDocument} from './ol/xml';
import {getDocument as _ol_xml$getDocument} from './ol/xml';
import $ol$worker$version from './ol/worker/version';
import $ol$worker$webgl from './ol/worker/webgl';
import $ol$webgl$Buffer from './ol/webgl/Buffer';
import {getArrayClassForType as _ol_webgl_Buffer$getArrayClassForType} from './ol/webgl/Buffer';
import $ol$webgl$Helper from './ol/webgl/Helper';
import {computeAttributesStride as _ol_webgl_Helper$computeAttributesStride} from './ol/webgl/Helper';
import $ol$webgl$PostProcessingPass from './ol/webgl/PostProcessingPass';
import $ol$webgl$RenderTarget from './ol/webgl/RenderTarget';
import {ShaderBuilder as _ol_webgl_ShaderBuilder$ShaderBuilder} from './ol/webgl/ShaderBuilder';
import {parseLiteralStyle as _ol_webgl_ShaderBuilder$parseLiteralStyle} from './ol/webgl/ShaderBuilder';
import $ol$webgl$ShaderBuilder from './ol/webgl/ShaderBuilder';
import {create as _ol_vec_mat4$create} from './ol/vec/mat4';
import {fromTransform as _ol_vec_mat4$fromTransform} from './ol/vec/mat4';
import $ol$tilegrid$TileGrid from './ol/tilegrid/TileGrid';
import $ol$tilegrid$WMTS from './ol/tilegrid/WMTS';
import {createFromCapabilitiesMatrixSet as _ol_tilegrid_WMTS$createFromCapabilitiesMatrixSet} from './ol/tilegrid/WMTS';
import {DEFAULT_MAX_ZOOM as _ol_tilegrid_common$DEFAULT_MAX_ZOOM} from './ol/tilegrid/common';
import $ol$tilegrid$common from './ol/tilegrid/common';
import {DEFAULT_TILE_SIZE as _ol_tilegrid_common$DEFAULT_TILE_SIZE} from './ol/tilegrid/common';
import $ol$style$Circle from './ol/style/Circle';
import $ol$style$Fill from './ol/style/Fill';
import $ol$style$Icon from './ol/style/Icon';
import $ol$style$IconImage from './ol/style/IconImage';
import {get as _ol_style_IconImage$get} from './ol/style/IconImage';
import $ol$style$IconImageCache from './ol/style/IconImageCache';
import {shared as _ol_style_IconImageCache$shared} from './ol/style/IconImageCache';
import $ol$style$Image from './ol/style/Image';
import $ol$style$LiteralStyle from './ol/style/LiteralStyle';
import $ol$style$RegularShape from './ol/style/RegularShape';
import $ol$style$Stroke from './ol/style/Stroke';
import $ol$style$Style from './ol/style/Style';
import {toFunction as _ol_style_Style$toFunction} from './ol/style/Style';
import {createDefaultStyle as _ol_style_Style$createDefaultStyle} from './ol/style/Style';
import {createEditingStyle as _ol_style_Style$createEditingStyle} from './ol/style/Style';
import $ol$style$Text from './ol/style/Text';
import $ol$style$expressions from './ol/style/expressions';
import {Operators as _ol_style_expressions$Operators} from './ol/style/expressions';
import {getValueType as _ol_style_expressions$getValueType} from './ol/style/expressions';
import {isTypeUnique as _ol_style_expressions$isTypeUnique} from './ol/style/expressions';
import {numberToGlsl as _ol_style_expressions$numberToGlsl} from './ol/style/expressions';
import {arrayToGlsl as _ol_style_expressions$arrayToGlsl} from './ol/style/expressions';
import {colorToGlsl as _ol_style_expressions$colorToGlsl} from './ol/style/expressions';
import {getStringNumberEquivalent as _ol_style_expressions$getStringNumberEquivalent} from './ol/style/expressions';
import {stringToGlsl as _ol_style_expressions$stringToGlsl} from './ol/style/expressions';
import {expressionToGlsl as _ol_style_expressions$expressionToGlsl} from './ol/style/expressions';
import $ol$structs$LRUCache from './ol/structs/LRUCache';
import $ol$structs$LinkedList from './ol/structs/LinkedList';
import {DROP as _ol_structs_PriorityQueue$DROP} from './ol/structs/PriorityQueue';
import $ol$structs$PriorityQueue from './ol/structs/PriorityQueue';
import $ol$structs$RBush from './ol/structs/RBush';
import {quadKey as _ol_source_BingMaps$quadKey} from './ol/source/BingMaps';
import $ol$source$BingMaps from './ol/source/BingMaps';
import $ol$source$CartoDB from './ol/source/CartoDB';
import $ol$source$Cluster from './ol/source/Cluster';
import $ol$source$IIIF from './ol/source/IIIF';
import $ol$source$Image from './ol/source/Image';
import {ImageSourceEvent as _ol_source_Image$ImageSourceEvent} from './ol/source/Image';
import {defaultImageLoadFunction as _ol_source_Image$defaultImageLoadFunction} from './ol/source/Image';
import $ol$source$ImageArcGISRest from './ol/source/ImageArcGISRest';
import $ol$source$ImageCanvas from './ol/source/ImageCanvas';
import $ol$source$ImageMapGuide from './ol/source/ImageMapGuide';
import $ol$source$ImageStatic from './ol/source/ImageStatic';
import $ol$source$ImageWMS from './ol/source/ImageWMS';
import {ATTRIBUTION as _ol_source_OSM$ATTRIBUTION} from './ol/source/OSM';
import $ol$source$OSM from './ol/source/OSM';
import $ol$source$Raster from './ol/source/Raster';
import {newImageData as _ol_source_Raster$newImageData} from './ol/source/Raster';
import {Processor as _ol_source_Raster$Processor} from './ol/source/Raster';
import {RasterSourceEvent as _ol_source_Raster$RasterSourceEvent} from './ol/source/Raster';
import $ol$source$Source from './ol/source/Source';
import $ol$source$Stamen from './ol/source/Stamen';
import $ol$source$Tile from './ol/source/Tile';
import {TileSourceEvent as _ol_source_Tile$TileSourceEvent} from './ol/source/Tile';
import $ol$source$TileArcGISRest from './ol/source/TileArcGISRest';
import $ol$source$TileDebug from './ol/source/TileDebug';
import $ol$source$TileImage from './ol/source/TileImage';
import $ol$source$TileJSON from './ol/source/TileJSON';
import $ol$source$TileWMS from './ol/source/TileWMS';
import {CustomTile as _ol_source_UTFGrid$CustomTile} from './ol/source/UTFGrid';
import $ol$source$UTFGrid from './ol/source/UTFGrid';
import $ol$source$UrlTile from './ol/source/UrlTile';
import {VectorSourceEvent as _ol_source_Vector$VectorSourceEvent} from './ol/source/Vector';
import $ol$source$Vector from './ol/source/Vector';
import $ol$source$VectorTile from './ol/source/VectorTile';
import {defaultLoadFunction as _ol_source_VectorTile$defaultLoadFunction} from './ol/source/VectorTile';
import $ol$source$WMTS from './ol/source/WMTS';
import {optionsFromCapabilities as _ol_source_WMTS$optionsFromCapabilities} from './ol/source/WMTS';
import $ol$source$XYZ from './ol/source/XYZ';
import {CustomTile as _ol_source_Zoomify$CustomTile} from './ol/source/Zoomify';
import $ol$source$Zoomify from './ol/source/Zoomify';
import {DEFAULT_WMS_VERSION as _ol_source_common$DEFAULT_WMS_VERSION} from './ol/source/common';
import $ol$source$common from './ol/source/common';
import {IMAGE_SMOOTHING_DISABLED as _ol_source_common$IMAGE_SMOOTHING_DISABLED} from './ol/source/common';
import $ol$reproj$Image from './ol/reproj/Image';
import $ol$reproj$Tile from './ol/reproj/Tile';
import $ol$reproj$Triangulation from './ol/reproj/Triangulation';
import {ERROR_THRESHOLD as _ol_reproj_common$ERROR_THRESHOLD} from './ol/reproj/common';
import $ol$reproj$common from './ol/reproj/common';
import {ENABLE_RASTER_REPROJECTION as _ol_reproj_common$ENABLE_RASTER_REPROJECTION} from './ol/reproj/common';
import $ol$renderer$Composite from './ol/renderer/Composite';
import $ol$renderer$Layer from './ol/renderer/Layer';
import $ol$renderer$Map from './ol/renderer/Map';
import $ol$renderer$vector from './ol/renderer/vector';
import {defaultOrder as _ol_renderer_vector$defaultOrder} from './ol/renderer/vector';
import {getSquaredTolerance as _ol_renderer_vector$getSquaredTolerance} from './ol/renderer/vector';
import {getTolerance as _ol_renderer_vector$getTolerance} from './ol/renderer/vector';
import {renderFeature as _ol_renderer_vector$renderFeature} from './ol/renderer/vector';
import $ol$renderer$webgl$Layer from './ol/renderer/webgl/Layer';
import {writePointFeatureToBuffers as _ol_renderer_webgl_Layer$writePointFeatureToBuffers} from './ol/renderer/webgl/Layer';
import {getBlankImageData as _ol_renderer_webgl_Layer$getBlankImageData} from './ol/renderer/webgl/Layer';
import {colorEncodeId as _ol_renderer_webgl_Layer$colorEncodeId} from './ol/renderer/webgl/Layer';
import {colorDecodeId as _ol_renderer_webgl_Layer$colorDecodeId} from './ol/renderer/webgl/Layer';
import $ol$renderer$webgl$PointsLayer from './ol/renderer/webgl/PointsLayer';
import $ol$renderer$canvas$ImageLayer from './ol/renderer/canvas/ImageLayer';
import $ol$renderer$canvas$Layer from './ol/renderer/canvas/Layer';
import $ol$renderer$canvas$TileLayer from './ol/renderer/canvas/TileLayer';
import $ol$renderer$canvas$VectorImageLayer from './ol/renderer/canvas/VectorImageLayer';
import $ol$renderer$canvas$VectorLayer from './ol/renderer/canvas/VectorLayer';
import $ol$renderer$canvas$VectorTileLayer from './ol/renderer/canvas/VectorTileLayer';
import $ol$render$Box from './ol/render/Box';
import $ol$render$Event from './ol/render/Event';
import $ol$render$Feature from './ol/render/Feature';
import $ol$render$VectorContext from './ol/render/VectorContext';
import $ol$render$canvas from './ol/render/canvas';
import {defaultFont as _ol_render_canvas$defaultFont} from './ol/render/canvas';
import {defaultFillStyle as _ol_render_canvas$defaultFillStyle} from './ol/render/canvas';
import {defaultLineCap as _ol_render_canvas$defaultLineCap} from './ol/render/canvas';
import {defaultLineDash as _ol_render_canvas$defaultLineDash} from './ol/render/canvas';
import {defaultLineDashOffset as _ol_render_canvas$defaultLineDashOffset} from './ol/render/canvas';
import {defaultLineJoin as _ol_render_canvas$defaultLineJoin} from './ol/render/canvas';
import {defaultMiterLimit as _ol_render_canvas$defaultMiterLimit} from './ol/render/canvas';
import {defaultStrokeStyle as _ol_render_canvas$defaultStrokeStyle} from './ol/render/canvas';
import {defaultTextAlign as _ol_render_canvas$defaultTextAlign} from './ol/render/canvas';
import {defaultTextBaseline as _ol_render_canvas$defaultTextBaseline} from './ol/render/canvas';
import {defaultPadding as _ol_render_canvas$defaultPadding} from './ol/render/canvas';
import {defaultLineWidth as _ol_render_canvas$defaultLineWidth} from './ol/render/canvas';
import {checkedFonts as _ol_render_canvas$checkedFonts} from './ol/render/canvas';
import {labelCache as _ol_render_canvas$labelCache} from './ol/render/canvas';
import {textHeights as _ol_render_canvas$textHeights} from './ol/render/canvas';
import {registerFont as _ol_render_canvas$registerFont} from './ol/render/canvas';
import {measureTextHeight as _ol_render_canvas$measureTextHeight} from './ol/render/canvas';
import {measureTextWidth as _ol_render_canvas$measureTextWidth} from './ol/render/canvas';
import {measureAndCacheTextWidth as _ol_render_canvas$measureAndCacheTextWidth} from './ol/render/canvas';
import {measureTextWidths as _ol_render_canvas$measureTextWidths} from './ol/render/canvas';
import {rotateAtOffset as _ol_render_canvas$rotateAtOffset} from './ol/render/canvas';
import {drawImageOrLabel as _ol_render_canvas$drawImageOrLabel} from './ol/render/canvas';
import {createTransformString as _ol_render_canvas$createTransformString} from './ol/render/canvas';
import $ol$render$canvas$Builder from './ol/render/canvas/Builder';
import $ol$render$canvas$BuilderGroup from './ol/render/canvas/BuilderGroup';
import $ol$render$canvas$Executor from './ol/render/canvas/Executor';
import $ol$render$canvas$ExecutorGroup from './ol/render/canvas/ExecutorGroup';
import {getPixelIndexArray as _ol_render_canvas_ExecutorGroup$getPixelIndexArray} from './ol/render/canvas/ExecutorGroup';
import $ol$render$canvas$ImageBuilder from './ol/render/canvas/ImageBuilder';
import $ol$render$canvas$Immediate from './ol/render/canvas/Immediate';
import {fillInstruction as _ol_render_canvas_Instruction$fillInstruction} from './ol/render/canvas/Instruction';
import $ol$render$canvas$Instruction from './ol/render/canvas/Instruction';
import {strokeInstruction as _ol_render_canvas_Instruction$strokeInstruction} from './ol/render/canvas/Instruction';
import {beginPathInstruction as _ol_render_canvas_Instruction$beginPathInstruction} from './ol/render/canvas/Instruction';
import {closePathInstruction as _ol_render_canvas_Instruction$closePathInstruction} from './ol/render/canvas/Instruction';
import $ol$render$canvas$LineStringBuilder from './ol/render/canvas/LineStringBuilder';
import $ol$render$canvas$PolygonBuilder from './ol/render/canvas/PolygonBuilder';
import $ol$render$canvas$TextBuilder from './ol/render/canvas/TextBuilder';
import {createHitDetectionImageData as _ol_render_canvas_hitdetect$createHitDetectionImageData} from './ol/render/canvas/hitdetect';
import {hitDetect as _ol_render_canvas_hitdetect$hitDetect} from './ol/render/canvas/hitdetect';
import $ol$proj$Projection from './ol/proj/Projection';
import {METERS_PER_UNIT as _ol_proj_Units$METERS_PER_UNIT} from './ol/proj/Units';
import $ol$proj$Units from './ol/proj/Units';
import {RADIUS as _ol_proj_epsg3857$RADIUS} from './ol/proj/epsg3857';
import $ol$proj$epsg3857 from './ol/proj/epsg3857';
import {HALF_SIZE as _ol_proj_epsg3857$HALF_SIZE} from './ol/proj/epsg3857';
import {EXTENT as _ol_proj_epsg3857$EXTENT} from './ol/proj/epsg3857';
import {WORLD_EXTENT as _ol_proj_epsg3857$WORLD_EXTENT} from './ol/proj/epsg3857';
import {MAX_SAFE_Y as _ol_proj_epsg3857$MAX_SAFE_Y} from './ol/proj/epsg3857';
import {PROJECTIONS as _ol_proj_epsg3857$PROJECTIONS} from './ol/proj/epsg3857';
import {fromEPSG4326 as _ol_proj_epsg3857$fromEPSG4326} from './ol/proj/epsg3857';
import {toEPSG4326 as _ol_proj_epsg3857$toEPSG4326} from './ol/proj/epsg3857';
import {RADIUS as _ol_proj_epsg4326$RADIUS} from './ol/proj/epsg4326';
import $ol$proj$epsg4326 from './ol/proj/epsg4326';
import {EXTENT as _ol_proj_epsg4326$EXTENT} from './ol/proj/epsg4326';
import {METERS_PER_UNIT as _ol_proj_epsg4326$METERS_PER_UNIT} from './ol/proj/epsg4326';
import {PROJECTIONS as _ol_proj_epsg4326$PROJECTIONS} from './ol/proj/epsg4326';
import {register as _ol_proj_proj4$register} from './ol/proj/proj4';
import {clear as _ol_proj_projections$clear} from './ol/proj/projections';
import {get as _ol_proj_projections$get} from './ol/proj/projections';
import {add as _ol_proj_projections$add} from './ol/proj/projections';
import {clear as _ol_proj_transforms$clear} from './ol/proj/transforms';
import {add as _ol_proj_transforms$add} from './ol/proj/transforms';
import {remove as _ol_proj_transforms$remove} from './ol/proj/transforms';
import {get as _ol_proj_transforms$get} from './ol/proj/transforms';
import $ol$layer$Base from './ol/layer/Base';
import $ol$layer$BaseImage from './ol/layer/BaseImage';
import $ol$layer$BaseTile from './ol/layer/BaseTile';
import $ol$layer$BaseVector from './ol/layer/BaseVector';
import $ol$layer$Graticule from './ol/layer/Graticule';
import $ol$layer$Group from './ol/layer/Group';
import $ol$layer$Heatmap from './ol/layer/Heatmap';
import $ol$layer$Image from './ol/layer/Image';
import $ol$layer$Layer from './ol/layer/Layer';
import {inView as _ol_layer_Layer$inView} from './ol/layer/Layer';
import $ol$layer$MapboxVector from './ol/layer/MapboxVector';
import {getMapboxPath as _ol_layer_MapboxVector$getMapboxPath} from './ol/layer/MapboxVector';
import {normalizeSpriteUrl as _ol_layer_MapboxVector$normalizeSpriteUrl} from './ol/layer/MapboxVector';
import {normalizeGlyphsUrl as _ol_layer_MapboxVector$normalizeGlyphsUrl} from './ol/layer/MapboxVector';
import {normalizeStyleUrl as _ol_layer_MapboxVector$normalizeStyleUrl} from './ol/layer/MapboxVector';
import {normalizeSourceUrl as _ol_layer_MapboxVector$normalizeSourceUrl} from './ol/layer/MapboxVector';
import $ol$layer$Tile from './ol/layer/Tile';
import $ol$layer$Vector from './ol/layer/Vector';
import $ol$layer$VectorImage from './ol/layer/VectorImage';
import $ol$layer$VectorTile from './ol/layer/VectorTile';
import $ol$layer$WebGLPoints from './ol/layer/WebGLPoints';
import $ol$interaction$DoubleClickZoom from './ol/interaction/DoubleClickZoom';
import {DragAndDropEvent as _ol_interaction_DragAndDrop$DragAndDropEvent} from './ol/interaction/DragAndDrop';
import $ol$interaction$DragAndDrop from './ol/interaction/DragAndDrop';
import {DragBoxEvent as _ol_interaction_DragBox$DragBoxEvent} from './ol/interaction/DragBox';
import $ol$interaction$DragBox from './ol/interaction/DragBox';
import $ol$interaction$DragPan from './ol/interaction/DragPan';
import $ol$interaction$DragRotate from './ol/interaction/DragRotate';
import $ol$interaction$DragRotateAndZoom from './ol/interaction/DragRotateAndZoom';
import $ol$interaction$DragZoom from './ol/interaction/DragZoom';
import {DrawEvent as _ol_interaction_Draw$DrawEvent} from './ol/interaction/Draw';
import $ol$interaction$Draw from './ol/interaction/Draw';
import {createRegularPolygon as _ol_interaction_Draw$createRegularPolygon} from './ol/interaction/Draw';
import {createBox as _ol_interaction_Draw$createBox} from './ol/interaction/Draw';
import {ExtentEvent as _ol_interaction_Extent$ExtentEvent} from './ol/interaction/Extent';
import $ol$interaction$Extent from './ol/interaction/Extent';
import $ol$interaction$Interaction from './ol/interaction/Interaction';
import {pan as _ol_interaction_Interaction$pan} from './ol/interaction/Interaction';
import {zoomByDelta as _ol_interaction_Interaction$zoomByDelta} from './ol/interaction/Interaction';
import $ol$interaction$KeyboardPan from './ol/interaction/KeyboardPan';
import $ol$interaction$KeyboardZoom from './ol/interaction/KeyboardZoom';
import $ol$interaction$Modify from './ol/interaction/Modify';
import {ModifyEvent as _ol_interaction_Modify$ModifyEvent} from './ol/interaction/Modify';
import $ol$interaction$MouseWheelZoom from './ol/interaction/MouseWheelZoom';
import $ol$interaction$PinchRotate from './ol/interaction/PinchRotate';
import $ol$interaction$PinchZoom from './ol/interaction/PinchZoom';
import $ol$interaction$Pointer from './ol/interaction/Pointer';
import {centroid as _ol_interaction_Pointer$centroid} from './ol/interaction/Pointer';
import {SelectEvent as _ol_interaction_Select$SelectEvent} from './ol/interaction/Select';
import $ol$interaction$Select from './ol/interaction/Select';
import $ol$interaction$Snap from './ol/interaction/Snap';
import {TranslateEvent as _ol_interaction_Translate$TranslateEvent} from './ol/interaction/Translate';
import $ol$interaction$Translate from './ol/interaction/Translate';
import $ol$geom$Circle from './ol/geom/Circle';
import $ol$geom$Geometry from './ol/geom/Geometry';
import $ol$geom$GeometryCollection from './ol/geom/GeometryCollection';
import $ol$geom$LineString from './ol/geom/LineString';
import $ol$geom$LinearRing from './ol/geom/LinearRing';
import $ol$geom$MultiLineString from './ol/geom/MultiLineString';
import $ol$geom$MultiPoint from './ol/geom/MultiPoint';
import $ol$geom$MultiPolygon from './ol/geom/MultiPolygon';
import $ol$geom$Point from './ol/geom/Point';
import $ol$geom$Polygon from './ol/geom/Polygon';
import {circular as _ol_geom_Polygon$circular} from './ol/geom/Polygon';
import {fromExtent as _ol_geom_Polygon$fromExtent} from './ol/geom/Polygon';
import {fromCircle as _ol_geom_Polygon$fromCircle} from './ol/geom/Polygon';
import {makeRegular as _ol_geom_Polygon$makeRegular} from './ol/geom/Polygon';
import $ol$geom$SimpleGeometry from './ol/geom/SimpleGeometry';
import {getStrideForLayout as _ol_geom_SimpleGeometry$getStrideForLayout} from './ol/geom/SimpleGeometry';
import {transformGeom2D as _ol_geom_SimpleGeometry$transformGeom2D} from './ol/geom/SimpleGeometry';
import {linearRing as _ol_geom_flat_area$linearRing} from './ol/geom/flat/area';
import {linearRings as _ol_geom_flat_area$linearRings} from './ol/geom/flat/area';
import {linearRingss as _ol_geom_flat_area$linearRingss} from './ol/geom/flat/area';
import {linearRingss as _ol_geom_flat_center$linearRingss} from './ol/geom/flat/center';
import $ol$geom$flat$closest from './ol/geom/flat/closest';
import {maxSquaredDelta as _ol_geom_flat_closest$maxSquaredDelta} from './ol/geom/flat/closest';
import {arrayMaxSquaredDelta as _ol_geom_flat_closest$arrayMaxSquaredDelta} from './ol/geom/flat/closest';
import {multiArrayMaxSquaredDelta as _ol_geom_flat_closest$multiArrayMaxSquaredDelta} from './ol/geom/flat/closest';
import {assignClosestPoint as _ol_geom_flat_closest$assignClosestPoint} from './ol/geom/flat/closest';
import {assignClosestArrayPoint as _ol_geom_flat_closest$assignClosestArrayPoint} from './ol/geom/flat/closest';
import {assignClosestMultiArrayPoint as _ol_geom_flat_closest$assignClosestMultiArrayPoint} from './ol/geom/flat/closest';
import {linearRingContainsExtent as _ol_geom_flat_contains$linearRingContainsExtent} from './ol/geom/flat/contains';
import {linearRingContainsXY as _ol_geom_flat_contains$linearRingContainsXY} from './ol/geom/flat/contains';
import {linearRingsContainsXY as _ol_geom_flat_contains$linearRingsContainsXY} from './ol/geom/flat/contains';
import {linearRingssContainsXY as _ol_geom_flat_contains$linearRingssContainsXY} from './ol/geom/flat/contains';
import {deflateCoordinate as _ol_geom_flat_deflate$deflateCoordinate} from './ol/geom/flat/deflate';
import {deflateCoordinates as _ol_geom_flat_deflate$deflateCoordinates} from './ol/geom/flat/deflate';
import {deflateCoordinatesArray as _ol_geom_flat_deflate$deflateCoordinatesArray} from './ol/geom/flat/deflate';
import {deflateMultiCoordinatesArray as _ol_geom_flat_deflate$deflateMultiCoordinatesArray} from './ol/geom/flat/deflate';
import {flipXY as _ol_geom_flat_flip$flipXY} from './ol/geom/flat/flip';
import $ol$geom$flat$geodesic from './ol/geom/flat/geodesic';
import {greatCircleArc as _ol_geom_flat_geodesic$greatCircleArc} from './ol/geom/flat/geodesic';
import {meridian as _ol_geom_flat_geodesic$meridian} from './ol/geom/flat/geodesic';
import {parallel as _ol_geom_flat_geodesic$parallel} from './ol/geom/flat/geodesic';
import {inflateCoordinates as _ol_geom_flat_inflate$inflateCoordinates} from './ol/geom/flat/inflate';
import {inflateCoordinatesArray as _ol_geom_flat_inflate$inflateCoordinatesArray} from './ol/geom/flat/inflate';
import {inflateMultiCoordinatesArray as _ol_geom_flat_inflate$inflateMultiCoordinatesArray} from './ol/geom/flat/inflate';
import {getInteriorPointOfArray as _ol_geom_flat_interiorpoint$getInteriorPointOfArray} from './ol/geom/flat/interiorpoint';
import {getInteriorPointsOfMultiArray as _ol_geom_flat_interiorpoint$getInteriorPointsOfMultiArray} from './ol/geom/flat/interiorpoint';
import {interpolatePoint as _ol_geom_flat_interpolate$interpolatePoint} from './ol/geom/flat/interpolate';
import {lineStringCoordinateAtM as _ol_geom_flat_interpolate$lineStringCoordinateAtM} from './ol/geom/flat/interpolate';
import {lineStringsCoordinateAtM as _ol_geom_flat_interpolate$lineStringsCoordinateAtM} from './ol/geom/flat/interpolate';
import {intersectsLineString as _ol_geom_flat_intersectsextent$intersectsLineString} from './ol/geom/flat/intersectsextent';
import {intersectsLineStringArray as _ol_geom_flat_intersectsextent$intersectsLineStringArray} from './ol/geom/flat/intersectsextent';
import {intersectsLinearRing as _ol_geom_flat_intersectsextent$intersectsLinearRing} from './ol/geom/flat/intersectsextent';
import {intersectsLinearRingArray as _ol_geom_flat_intersectsextent$intersectsLinearRingArray} from './ol/geom/flat/intersectsextent';
import {intersectsLinearRingMultiArray as _ol_geom_flat_intersectsextent$intersectsLinearRingMultiArray} from './ol/geom/flat/intersectsextent';
import {lineStringLength as _ol_geom_flat_length$lineStringLength} from './ol/geom/flat/length';
import {linearRingLength as _ol_geom_flat_length$linearRingLength} from './ol/geom/flat/length';
import {linearRingIsClockwise as _ol_geom_flat_orient$linearRingIsClockwise} from './ol/geom/flat/orient';
import {linearRingsAreOriented as _ol_geom_flat_orient$linearRingsAreOriented} from './ol/geom/flat/orient';
import {linearRingssAreOriented as _ol_geom_flat_orient$linearRingssAreOriented} from './ol/geom/flat/orient';
import {orientLinearRings as _ol_geom_flat_orient$orientLinearRings} from './ol/geom/flat/orient';
import {orientLinearRingsArray as _ol_geom_flat_orient$orientLinearRingsArray} from './ol/geom/flat/orient';
import {coordinates as _ol_geom_flat_reverse$coordinates} from './ol/geom/flat/reverse';
import {forEach as _ol_geom_flat_segments$forEach} from './ol/geom/flat/segments';
import {simplifyLineString as _ol_geom_flat_simplify$simplifyLineString} from './ol/geom/flat/simplify';
import {douglasPeucker as _ol_geom_flat_simplify$douglasPeucker} from './ol/geom/flat/simplify';
import {douglasPeuckerArray as _ol_geom_flat_simplify$douglasPeuckerArray} from './ol/geom/flat/simplify';
import {douglasPeuckerMultiArray as _ol_geom_flat_simplify$douglasPeuckerMultiArray} from './ol/geom/flat/simplify';
import {radialDistance as _ol_geom_flat_simplify$radialDistance} from './ol/geom/flat/simplify';
import {snap as _ol_geom_flat_simplify$snap} from './ol/geom/flat/simplify';
import {quantize as _ol_geom_flat_simplify$quantize} from './ol/geom/flat/simplify';
import {quantizeArray as _ol_geom_flat_simplify$quantizeArray} from './ol/geom/flat/simplify';
import {quantizeMultiArray as _ol_geom_flat_simplify$quantizeMultiArray} from './ol/geom/flat/simplify';
import {matchingChunk as _ol_geom_flat_straightchunk$matchingChunk} from './ol/geom/flat/straightchunk';
import {drawTextOnPath as _ol_geom_flat_textpath$drawTextOnPath} from './ol/geom/flat/textpath';
import {lineStringIsClosed as _ol_geom_flat_topology$lineStringIsClosed} from './ol/geom/flat/topology';
import {transform2D as _ol_geom_flat_transform$transform2D} from './ol/geom/flat/transform';
import {rotate as _ol_geom_flat_transform$rotate} from './ol/geom/flat/transform';
import {scale as _ol_geom_flat_transform$scale} from './ol/geom/flat/transform';
import {translate as _ol_geom_flat_transform$translate} from './ol/geom/flat/transform';
import $ol$format$EsriJSON from './ol/format/EsriJSON';
import $ol$format$Feature from './ol/format/Feature';
import {transformGeometryWithOptions as _ol_format_Feature$transformGeometryWithOptions} from './ol/format/Feature';
import {transformExtentWithOptions as _ol_format_Feature$transformExtentWithOptions} from './ol/format/Feature';
import $ol$format$GML from './ol/format/GML';
import $ol$format$GML2 from './ol/format/GML2';
import $ol$format$GML3 from './ol/format/GML3';
import $ol$format$GML32 from './ol/format/GML32';
import {GMLNS as _ol_format_GMLBase$GMLNS} from './ol/format/GMLBase';
import $ol$format$GMLBase from './ol/format/GMLBase';
import $ol$format$GPX from './ol/format/GPX';
import $ol$format$GeoJSON from './ol/format/GeoJSON';
import $ol$format$IGC from './ol/format/IGC';
import $ol$format$IIIFInfo from './ol/format/IIIFInfo';
import $ol$format$JSONFeature from './ol/format/JSONFeature';
import $ol$format$KML from './ol/format/KML';
import {getDefaultFillStyle as _ol_format_KML$getDefaultFillStyle} from './ol/format/KML';
import {getDefaultImageStyle as _ol_format_KML$getDefaultImageStyle} from './ol/format/KML';
import {getDefaultStrokeStyle as _ol_format_KML$getDefaultStrokeStyle} from './ol/format/KML';
import {getDefaultTextStyle as _ol_format_KML$getDefaultTextStyle} from './ol/format/KML';
import {getDefaultStyle as _ol_format_KML$getDefaultStyle} from './ol/format/KML';
import {getDefaultStyleArray as _ol_format_KML$getDefaultStyleArray} from './ol/format/KML';
import {readFlatCoordinates as _ol_format_KML$readFlatCoordinates} from './ol/format/KML';
import $ol$format$MVT from './ol/format/MVT';
import $ol$format$OSMXML from './ol/format/OSMXML';
import $ol$format$OWS from './ol/format/OWS';
import $ol$format$Polyline from './ol/format/Polyline';
import {encodeDeltas as _ol_format_Polyline$encodeDeltas} from './ol/format/Polyline';
import {decodeDeltas as _ol_format_Polyline$decodeDeltas} from './ol/format/Polyline';
import {encodeFloats as _ol_format_Polyline$encodeFloats} from './ol/format/Polyline';
import {decodeFloats as _ol_format_Polyline$decodeFloats} from './ol/format/Polyline';
import {encodeSignedIntegers as _ol_format_Polyline$encodeSignedIntegers} from './ol/format/Polyline';
import {decodeSignedIntegers as _ol_format_Polyline$decodeSignedIntegers} from './ol/format/Polyline';
import {encodeUnsignedIntegers as _ol_format_Polyline$encodeUnsignedIntegers} from './ol/format/Polyline';
import {decodeUnsignedIntegers as _ol_format_Polyline$decodeUnsignedIntegers} from './ol/format/Polyline';
import {encodeUnsignedInteger as _ol_format_Polyline$encodeUnsignedInteger} from './ol/format/Polyline';
import $ol$format$TextFeature from './ol/format/TextFeature';
import $ol$format$TopoJSON from './ol/format/TopoJSON';
import $ol$format$WFS from './ol/format/WFS';
import {writeFilter as _ol_format_WFS$writeFilter} from './ol/format/WFS';
import $ol$format$WKT from './ol/format/WKT';
import $ol$format$WMSCapabilities from './ol/format/WMSCapabilities';
import $ol$format$WMSGetFeatureInfo from './ol/format/WMSGetFeatureInfo';
import $ol$format$WMTSCapabilities from './ol/format/WMTSCapabilities';
import $ol$format$XLink from './ol/format/XLink';
import {readHref as _ol_format_XLink$readHref} from './ol/format/XLink';
import $ol$format$XML from './ol/format/XML';
import $ol$format$XMLFeature from './ol/format/XMLFeature';
import {and as _ol_format_filter$and} from './ol/format/filter';
import {or as _ol_format_filter$or} from './ol/format/filter';
import {not as _ol_format_filter$not} from './ol/format/filter';
import {bbox as _ol_format_filter$bbox} from './ol/format/filter';
import {contains as _ol_format_filter$contains} from './ol/format/filter';
import {intersects as _ol_format_filter$intersects} from './ol/format/filter';
import {disjoint as _ol_format_filter$disjoint} from './ol/format/filter';
import {within as _ol_format_filter$within} from './ol/format/filter';
import {dwithin as _ol_format_filter$dwithin} from './ol/format/filter';
import {equalTo as _ol_format_filter$equalTo} from './ol/format/filter';
import {notEqualTo as _ol_format_filter$notEqualTo} from './ol/format/filter';
import {lessThan as _ol_format_filter$lessThan} from './ol/format/filter';
import {lessThanOrEqualTo as _ol_format_filter$lessThanOrEqualTo} from './ol/format/filter';
import {greaterThan as _ol_format_filter$greaterThan} from './ol/format/filter';
import {greaterThanOrEqualTo as _ol_format_filter$greaterThanOrEqualTo} from './ol/format/filter';
import {isNull as _ol_format_filter$isNull} from './ol/format/filter';
import {between as _ol_format_filter$between} from './ol/format/filter';
import {like as _ol_format_filter$like} from './ol/format/filter';
import {during as _ol_format_filter$during} from './ol/format/filter';
import {resourceId as _ol_format_filter$resourceId} from './ol/format/filter';
import {readBoolean as _ol_format_xsd$readBoolean} from './ol/format/xsd';
import {readBooleanString as _ol_format_xsd$readBooleanString} from './ol/format/xsd';
import {readDateTime as _ol_format_xsd$readDateTime} from './ol/format/xsd';
import {readDecimal as _ol_format_xsd$readDecimal} from './ol/format/xsd';
import {readDecimalString as _ol_format_xsd$readDecimalString} from './ol/format/xsd';
import {readNonNegativeInteger as _ol_format_xsd$readNonNegativeInteger} from './ol/format/xsd';
import {readNonNegativeIntegerString as _ol_format_xsd$readNonNegativeIntegerString} from './ol/format/xsd';
import {readString as _ol_format_xsd$readString} from './ol/format/xsd';
import {writeBooleanTextNode as _ol_format_xsd$writeBooleanTextNode} from './ol/format/xsd';
import {writeCDATASection as _ol_format_xsd$writeCDATASection} from './ol/format/xsd';
import {writeDateTimeTextNode as _ol_format_xsd$writeDateTimeTextNode} from './ol/format/xsd';
import {writeDecimalTextNode as _ol_format_xsd$writeDecimalTextNode} from './ol/format/xsd';
import {writeNonNegativeIntegerTextNode as _ol_format_xsd$writeNonNegativeIntegerTextNode} from './ol/format/xsd';
import {writeStringTextNode as _ol_format_xsd$writeStringTextNode} from './ol/format/xsd';
import $ol$format$filter$And from './ol/format/filter/And';
import $ol$format$filter$Bbox from './ol/format/filter/Bbox';
import $ol$format$filter$Comparison from './ol/format/filter/Comparison';
import $ol$format$filter$ComparisonBinary from './ol/format/filter/ComparisonBinary';
import $ol$format$filter$Contains from './ol/format/filter/Contains';
import $ol$format$filter$DWithin from './ol/format/filter/DWithin';
import $ol$format$filter$Disjoint from './ol/format/filter/Disjoint';
import $ol$format$filter$During from './ol/format/filter/During';
import $ol$format$filter$EqualTo from './ol/format/filter/EqualTo';
import $ol$format$filter$Filter from './ol/format/filter/Filter';
import $ol$format$filter$GreaterThan from './ol/format/filter/GreaterThan';
import $ol$format$filter$GreaterThanOrEqualTo from './ol/format/filter/GreaterThanOrEqualTo';
import $ol$format$filter$Intersects from './ol/format/filter/Intersects';
import $ol$format$filter$IsBetween from './ol/format/filter/IsBetween';
import $ol$format$filter$IsLike from './ol/format/filter/IsLike';
import $ol$format$filter$IsNull from './ol/format/filter/IsNull';
import $ol$format$filter$LessThan from './ol/format/filter/LessThan';
import $ol$format$filter$LessThanOrEqualTo from './ol/format/filter/LessThanOrEqualTo';
import $ol$format$filter$LogicalNary from './ol/format/filter/LogicalNary';
import $ol$format$filter$Not from './ol/format/filter/Not';
import $ol$format$filter$NotEqualTo from './ol/format/filter/NotEqualTo';
import $ol$format$filter$Or from './ol/format/filter/Or';
import $ol$format$filter$ResourceId from './ol/format/filter/ResourceId';
import $ol$format$filter$Spatial from './ol/format/filter/Spatial';
import $ol$format$filter$Within from './ol/format/filter/Within';
import $ol$events$Event from './ol/events/Event';
import {stopPropagation as _ol_events_Event$stopPropagation} from './ol/events/Event';
import {preventDefault as _ol_events_Event$preventDefault} from './ol/events/Event';
import $ol$events$Target from './ol/events/Target';
import {all as _ol_events_condition$all} from './ol/events/condition';
import {altKeyOnly as _ol_events_condition$altKeyOnly} from './ol/events/condition';
import $ol$events$condition from './ol/events/condition';
import {altShiftKeysOnly as _ol_events_condition$altShiftKeysOnly} from './ol/events/condition';
import {focus as _ol_events_condition$focus} from './ol/events/condition';
import {focusWithTabindex as _ol_events_condition$focusWithTabindex} from './ol/events/condition';
import {always as _ol_events_condition$always} from './ol/events/condition';
import {click as _ol_events_condition$click} from './ol/events/condition';
import {mouseActionButton as _ol_events_condition$mouseActionButton} from './ol/events/condition';
import {never as _ol_events_condition$never} from './ol/events/condition';
import {pointerMove as _ol_events_condition$pointerMove} from './ol/events/condition';
import {singleClick as _ol_events_condition$singleClick} from './ol/events/condition';
import {doubleClick as _ol_events_condition$doubleClick} from './ol/events/condition';
import {noModifierKeys as _ol_events_condition$noModifierKeys} from './ol/events/condition';
import {platformModifierKeyOnly as _ol_events_condition$platformModifierKeyOnly} from './ol/events/condition';
import {shiftKeyOnly as _ol_events_condition$shiftKeyOnly} from './ol/events/condition';
import {targetNotEditable as _ol_events_condition$targetNotEditable} from './ol/events/condition';
import {mouseOnly as _ol_events_condition$mouseOnly} from './ol/events/condition';
import {touchOnly as _ol_events_condition$touchOnly} from './ol/events/condition';
import {penOnly as _ol_events_condition$penOnly} from './ol/events/condition';
import {primaryAction as _ol_events_condition$primaryAction} from './ol/events/condition';
import $ol$control$Attribution from './ol/control/Attribution';
import $ol$control$Control from './ol/control/Control';
import $ol$control$FullScreen from './ol/control/FullScreen';
import $ol$control$MousePosition from './ol/control/MousePosition';
import $ol$control$OverviewMap from './ol/control/OverviewMap';
import $ol$control$Rotate from './ol/control/Rotate';
import $ol$control$ScaleLine from './ol/control/ScaleLine';
import $ol$control$Zoom from './ol/control/Zoom';
import $ol$control$ZoomSlider from './ol/control/ZoomSlider';
import $ol$control$ZoomToExtent from './ol/control/ZoomToExtent';

var ol = {};
ol.AssertionError = $ol$AssertionError || {};
ol.Collection = $ol$Collection || {};
ol.Collection.CollectionEvent = _ol_Collection$CollectionEvent || {};
ol.Disposable = $ol$Disposable || {};
ol.Feature = $ol$Feature || {};
ol.Feature.createStyleFunction = _ol_Feature$createStyleFunction || {};
ol.Geolocation = $ol$Geolocation || {};
ol.Image = $ol$Image || {};
ol.Image.listenImage = _ol_Image$listenImage || {};
ol.ImageBase = $ol$ImageBase || {};
ol.ImageCanvas = $ol$ImageCanvas || {};
ol.ImageTile = $ol$ImageTile || {};
ol.Kinetic = $ol$Kinetic || {};
ol.Map = $ol$Map || {};
ol.MapBrowserEvent = $ol$MapBrowserEvent || {};
ol.MapBrowserEventHandler = $ol$MapBrowserEventHandler || {};
ol.MapEvent = $ol$MapEvent || {};
ol.Object = $ol$Object || {};
ol.Object.ObjectEvent = _ol_Object$ObjectEvent || {};
ol.Object.getChangeEventType = _ol_Object$getChangeEventType || {};
ol.Observable = $ol$Observable || {};
ol.Observable.unByKey = _ol_Observable$unByKey || {};
ol.Overlay = $ol$Overlay || {};
ol.PluggableMap = $ol$PluggableMap || {};
ol.Tile = $ol$Tile || {};
ol.TileCache = $ol$TileCache || {};
ol.TileQueue = $ol$TileQueue || {};
ol.TileQueue.getTilePriority = _ol_TileQueue$getTilePriority || {};
ol.TileRange = $ol$TileRange || {};
ol.TileRange.createOrUpdate = _ol_TileRange$createOrUpdate || {};
ol.VectorRenderTile = $ol$VectorRenderTile || {};
ol.VectorTile = $ol$VectorTile || {};
ol.View = $ol$View || {};
ol.View.createCenterConstraint = _ol_View$createCenterConstraint || {};
ol.View.createResolutionConstraint = _ol_View$createResolutionConstraint || {};
ol.View.createRotationConstraint = _ol_View$createRotationConstraint || {};
ol.View.isNoopAnimation = _ol_View$isNoopAnimation || {};
ol.array = {};
ol.array.binarySearch = _ol_array$binarySearch || {};
ol.array.equals = _ol_array$equals || {};
ol.array.extend = _ol_array$extend || {};
ol.array.find = _ol_array$find || {};
ol.array.findIndex = _ol_array$findIndex || {};
ol.array.includes = _ol_array$includes || {};
ol.array.isSorted = _ol_array$isSorted || {};
ol.array.linearFindNearest = _ol_array$linearFindNearest || {};
ol.array.numberSafeCompareFunction = _ol_array$numberSafeCompareFunction || {};
ol.array.remove = _ol_array$remove || {};
ol.array.reverseSubArray = _ol_array$reverseSubArray || {};
ol.array.stableSort = _ol_array$stableSort || {};
ol.asserts = {};
ol.asserts.assert = _ol_asserts$assert || {};
ol.centerconstraint = {};
ol.centerconstraint.createExtent = _ol_centerconstraint$createExtent || {};
ol.centerconstraint.none = _ol_centerconstraint$none || {};
ol.color = $ol$color || {};
ol.color.asArray = _ol_color$asArray || {};
ol.color.asString = _ol_color$asString || {};
ol.color.fromString = _ol_color$fromString || {};
ol.color.isStringColor = _ol_color$isStringColor || {};
ol.color.normalize = _ol_color$normalize || {};
ol.color.toString = _ol_color$toString || {};
ol.colorlike = {};
ol.colorlike.asColorLike = _ol_colorlike$asColorLike || {};
ol.control = {};
ol.control.Attribution = $ol$control$Attribution || {};
ol.control.Control = $ol$control$Control || {};
ol.control.FullScreen = $ol$control$FullScreen || {};
ol.control.MousePosition = $ol$control$MousePosition || {};
ol.control.OverviewMap = $ol$control$OverviewMap || {};
ol.control.Rotate = $ol$control$Rotate || {};
ol.control.ScaleLine = $ol$control$ScaleLine || {};
ol.control.Zoom = $ol$control$Zoom || {};
ol.control.ZoomSlider = $ol$control$ZoomSlider || {};
ol.control.ZoomToExtent = $ol$control$ZoomToExtent || {};
ol.control.defaults = _ol_control$defaults || {};
ol.coordinate = {};
ol.coordinate.add = _ol_coordinate$add || {};
ol.coordinate.closestOnCircle = _ol_coordinate$closestOnCircle || {};
ol.coordinate.closestOnSegment = _ol_coordinate$closestOnSegment || {};
ol.coordinate.createStringXY = _ol_coordinate$createStringXY || {};
ol.coordinate.degreesToStringHDMS = _ol_coordinate$degreesToStringHDMS || {};
ol.coordinate.distance = _ol_coordinate$distance || {};
ol.coordinate.equals = _ol_coordinate$equals || {};
ol.coordinate.format = _ol_coordinate$format || {};
ol.coordinate.getWorldsAway = _ol_coordinate$getWorldsAway || {};
ol.coordinate.rotate = _ol_coordinate$rotate || {};
ol.coordinate.scale = _ol_coordinate$scale || {};
ol.coordinate.squaredDistance = _ol_coordinate$squaredDistance || {};
ol.coordinate.squaredDistanceToSegment = _ol_coordinate$squaredDistanceToSegment || {};
ol.coordinate.toStringHDMS = _ol_coordinate$toStringHDMS || {};
ol.coordinate.toStringXY = _ol_coordinate$toStringXY || {};
ol.coordinate.wrapX = _ol_coordinate$wrapX || {};
ol.css = $ol$css || {};
ol.css.CLASS_COLLAPSED = _ol_css$CLASS_COLLAPSED || {};
ol.css.CLASS_CONTROL = _ol_css$CLASS_CONTROL || {};
ol.css.CLASS_HIDDEN = _ol_css$CLASS_HIDDEN || {};
ol.css.CLASS_SELECTABLE = _ol_css$CLASS_SELECTABLE || {};
ol.css.CLASS_UNSELECTABLE = _ol_css$CLASS_UNSELECTABLE || {};
ol.css.CLASS_UNSUPPORTED = _ol_css$CLASS_UNSUPPORTED || {};
ol.css.getFontParameters = _ol_css$getFontParameters || {};
ol.dom = {};
ol.dom.createCanvasContext2D = _ol_dom$createCanvasContext2D || {};
ol.dom.outerHeight = _ol_dom$outerHeight || {};
ol.dom.outerWidth = _ol_dom$outerWidth || {};
ol.dom.removeChildren = _ol_dom$removeChildren || {};
ol.dom.removeNode = _ol_dom$removeNode || {};
ol.dom.replaceChildren = _ol_dom$replaceChildren || {};
ol.dom.replaceNode = _ol_dom$replaceNode || {};
ol.easing = {};
ol.easing.easeIn = _ol_easing$easeIn || {};
ol.easing.easeOut = _ol_easing$easeOut || {};
ol.easing.inAndOut = _ol_easing$inAndOut || {};
ol.easing.linear = _ol_easing$linear || {};
ol.easing.upAndDown = _ol_easing$upAndDown || {};
ol.events = $ol$events || {};
ol.events.Event = $ol$events$Event || {};
ol.events.Event.preventDefault = _ol_events_Event$preventDefault || {};
ol.events.Event.stopPropagation = _ol_events_Event$stopPropagation || {};
ol.events.Target = $ol$events$Target || {};
ol.events.condition = $ol$events$condition || {};
ol.events.condition.all = _ol_events_condition$all || {};
ol.events.condition.altKeyOnly = _ol_events_condition$altKeyOnly || {};
ol.events.condition.altShiftKeysOnly = _ol_events_condition$altShiftKeysOnly || {};
ol.events.condition.always = _ol_events_condition$always || {};
ol.events.condition.click = _ol_events_condition$click || {};
ol.events.condition.doubleClick = _ol_events_condition$doubleClick || {};
ol.events.condition.focus = _ol_events_condition$focus || {};
ol.events.condition.focusWithTabindex = _ol_events_condition$focusWithTabindex || {};
ol.events.condition.mouseActionButton = _ol_events_condition$mouseActionButton || {};
ol.events.condition.mouseOnly = _ol_events_condition$mouseOnly || {};
ol.events.condition.never = _ol_events_condition$never || {};
ol.events.condition.noModifierKeys = _ol_events_condition$noModifierKeys || {};
ol.events.condition.penOnly = _ol_events_condition$penOnly || {};
ol.events.condition.platformModifierKeyOnly = _ol_events_condition$platformModifierKeyOnly || {};
ol.events.condition.pointerMove = _ol_events_condition$pointerMove || {};
ol.events.condition.primaryAction = _ol_events_condition$primaryAction || {};
ol.events.condition.shiftKeyOnly = _ol_events_condition$shiftKeyOnly || {};
ol.events.condition.singleClick = _ol_events_condition$singleClick || {};
ol.events.condition.targetNotEditable = _ol_events_condition$targetNotEditable || {};
ol.events.condition.touchOnly = _ol_events_condition$touchOnly || {};
ol.events.listen = _ol_events$listen || {};
ol.events.listenOnce = _ol_events$listenOnce || {};
ol.events.unlistenByKey = _ol_events$unlistenByKey || {};
ol.extent = $ol$extent || {};
ol.extent.applyTransform = _ol_extent$applyTransform || {};
ol.extent.approximatelyEquals = _ol_extent$approximatelyEquals || {};
ol.extent.boundingExtent = _ol_extent$boundingExtent || {};
ol.extent.buffer = _ol_extent$buffer || {};
ol.extent.clone = _ol_extent$clone || {};
ol.extent.closestSquaredDistanceXY = _ol_extent$closestSquaredDistanceXY || {};
ol.extent.containsCoordinate = _ol_extent$containsCoordinate || {};
ol.extent.containsExtent = _ol_extent$containsExtent || {};
ol.extent.containsXY = _ol_extent$containsXY || {};
ol.extent.coordinateRelationship = _ol_extent$coordinateRelationship || {};
ol.extent.createEmpty = _ol_extent$createEmpty || {};
ol.extent.createOrUpdate = _ol_extent$createOrUpdate || {};
ol.extent.createOrUpdateEmpty = _ol_extent$createOrUpdateEmpty || {};
ol.extent.createOrUpdateFromCoordinate = _ol_extent$createOrUpdateFromCoordinate || {};
ol.extent.createOrUpdateFromCoordinates = _ol_extent$createOrUpdateFromCoordinates || {};
ol.extent.createOrUpdateFromFlatCoordinates = _ol_extent$createOrUpdateFromFlatCoordinates || {};
ol.extent.createOrUpdateFromRings = _ol_extent$createOrUpdateFromRings || {};
ol.extent.equals = _ol_extent$equals || {};
ol.extent.extend = _ol_extent$extend || {};
ol.extent.extendCoordinate = _ol_extent$extendCoordinate || {};
ol.extent.extendCoordinates = _ol_extent$extendCoordinates || {};
ol.extent.extendFlatCoordinates = _ol_extent$extendFlatCoordinates || {};
ol.extent.extendRings = _ol_extent$extendRings || {};
ol.extent.extendXY = _ol_extent$extendXY || {};
ol.extent.forEachCorner = _ol_extent$forEachCorner || {};
ol.extent.getArea = _ol_extent$getArea || {};
ol.extent.getBottomLeft = _ol_extent$getBottomLeft || {};
ol.extent.getBottomRight = _ol_extent$getBottomRight || {};
ol.extent.getCenter = _ol_extent$getCenter || {};
ol.extent.getCorner = _ol_extent$getCorner || {};
ol.extent.getEnlargedArea = _ol_extent$getEnlargedArea || {};
ol.extent.getForViewAndSize = _ol_extent$getForViewAndSize || {};
ol.extent.getHeight = _ol_extent$getHeight || {};
ol.extent.getIntersection = _ol_extent$getIntersection || {};
ol.extent.getIntersectionArea = _ol_extent$getIntersectionArea || {};
ol.extent.getMargin = _ol_extent$getMargin || {};
ol.extent.getSize = _ol_extent$getSize || {};
ol.extent.getTopLeft = _ol_extent$getTopLeft || {};
ol.extent.getTopRight = _ol_extent$getTopRight || {};
ol.extent.getWidth = _ol_extent$getWidth || {};
ol.extent.intersects = _ol_extent$intersects || {};
ol.extent.intersectsSegment = _ol_extent$intersectsSegment || {};
ol.extent.isEmpty = _ol_extent$isEmpty || {};
ol.extent.returnOrUpdate = _ol_extent$returnOrUpdate || {};
ol.extent.scaleFromCenter = _ol_extent$scaleFromCenter || {};
ol.extent.wrapX = _ol_extent$wrapX || {};
ol.featureloader = {};
ol.featureloader.loadFeaturesXhr = _ol_featureloader$loadFeaturesXhr || {};
ol.featureloader.setWithCredentials = _ol_featureloader$setWithCredentials || {};
ol.featureloader.xhr = _ol_featureloader$xhr || {};
ol.format = {};
ol.format.EsriJSON = $ol$format$EsriJSON || {};
ol.format.Feature = $ol$format$Feature || {};
ol.format.Feature.transformExtentWithOptions = _ol_format_Feature$transformExtentWithOptions || {};
ol.format.Feature.transformGeometryWithOptions = _ol_format_Feature$transformGeometryWithOptions || {};
ol.format.GML = $ol$format$GML || {};
ol.format.GML2 = $ol$format$GML2 || {};
ol.format.GML3 = $ol$format$GML3 || {};
ol.format.GML32 = $ol$format$GML32 || {};
ol.format.GMLBase = $ol$format$GMLBase || {};
ol.format.GMLBase.GMLNS = _ol_format_GMLBase$GMLNS || {};
ol.format.GPX = $ol$format$GPX || {};
ol.format.GeoJSON = $ol$format$GeoJSON || {};
ol.format.IGC = $ol$format$IGC || {};
ol.format.IIIFInfo = $ol$format$IIIFInfo || {};
ol.format.JSONFeature = $ol$format$JSONFeature || {};
ol.format.KML = $ol$format$KML || {};
ol.format.KML.getDefaultFillStyle = _ol_format_KML$getDefaultFillStyle || {};
ol.format.KML.getDefaultImageStyle = _ol_format_KML$getDefaultImageStyle || {};
ol.format.KML.getDefaultStrokeStyle = _ol_format_KML$getDefaultStrokeStyle || {};
ol.format.KML.getDefaultStyle = _ol_format_KML$getDefaultStyle || {};
ol.format.KML.getDefaultStyleArray = _ol_format_KML$getDefaultStyleArray || {};
ol.format.KML.getDefaultTextStyle = _ol_format_KML$getDefaultTextStyle || {};
ol.format.KML.readFlatCoordinates = _ol_format_KML$readFlatCoordinates || {};
ol.format.MVT = $ol$format$MVT || {};
ol.format.OSMXML = $ol$format$OSMXML || {};
ol.format.OWS = $ol$format$OWS || {};
ol.format.Polyline = $ol$format$Polyline || {};
ol.format.Polyline.decodeDeltas = _ol_format_Polyline$decodeDeltas || {};
ol.format.Polyline.decodeFloats = _ol_format_Polyline$decodeFloats || {};
ol.format.Polyline.decodeSignedIntegers = _ol_format_Polyline$decodeSignedIntegers || {};
ol.format.Polyline.decodeUnsignedIntegers = _ol_format_Polyline$decodeUnsignedIntegers || {};
ol.format.Polyline.encodeDeltas = _ol_format_Polyline$encodeDeltas || {};
ol.format.Polyline.encodeFloats = _ol_format_Polyline$encodeFloats || {};
ol.format.Polyline.encodeSignedIntegers = _ol_format_Polyline$encodeSignedIntegers || {};
ol.format.Polyline.encodeUnsignedInteger = _ol_format_Polyline$encodeUnsignedInteger || {};
ol.format.Polyline.encodeUnsignedIntegers = _ol_format_Polyline$encodeUnsignedIntegers || {};
ol.format.TextFeature = $ol$format$TextFeature || {};
ol.format.TopoJSON = $ol$format$TopoJSON || {};
ol.format.WFS = $ol$format$WFS || {};
ol.format.WFS.writeFilter = _ol_format_WFS$writeFilter || {};
ol.format.WKT = $ol$format$WKT || {};
ol.format.WMSCapabilities = $ol$format$WMSCapabilities || {};
ol.format.WMSGetFeatureInfo = $ol$format$WMSGetFeatureInfo || {};
ol.format.WMTSCapabilities = $ol$format$WMTSCapabilities || {};
ol.format.XLink = $ol$format$XLink || {};
ol.format.XLink.readHref = _ol_format_XLink$readHref || {};
ol.format.XML = $ol$format$XML || {};
ol.format.XMLFeature = $ol$format$XMLFeature || {};
ol.format.filter = {};
ol.format.filter.And = $ol$format$filter$And || {};
ol.format.filter.Bbox = $ol$format$filter$Bbox || {};
ol.format.filter.Comparison = $ol$format$filter$Comparison || {};
ol.format.filter.ComparisonBinary = $ol$format$filter$ComparisonBinary || {};
ol.format.filter.Contains = $ol$format$filter$Contains || {};
ol.format.filter.DWithin = $ol$format$filter$DWithin || {};
ol.format.filter.Disjoint = $ol$format$filter$Disjoint || {};
ol.format.filter.During = $ol$format$filter$During || {};
ol.format.filter.EqualTo = $ol$format$filter$EqualTo || {};
ol.format.filter.Filter = $ol$format$filter$Filter || {};
ol.format.filter.GreaterThan = $ol$format$filter$GreaterThan || {};
ol.format.filter.GreaterThanOrEqualTo = $ol$format$filter$GreaterThanOrEqualTo || {};
ol.format.filter.Intersects = $ol$format$filter$Intersects || {};
ol.format.filter.IsBetween = $ol$format$filter$IsBetween || {};
ol.format.filter.IsLike = $ol$format$filter$IsLike || {};
ol.format.filter.IsNull = $ol$format$filter$IsNull || {};
ol.format.filter.LessThan = $ol$format$filter$LessThan || {};
ol.format.filter.LessThanOrEqualTo = $ol$format$filter$LessThanOrEqualTo || {};
ol.format.filter.LogicalNary = $ol$format$filter$LogicalNary || {};
ol.format.filter.Not = $ol$format$filter$Not || {};
ol.format.filter.NotEqualTo = $ol$format$filter$NotEqualTo || {};
ol.format.filter.Or = $ol$format$filter$Or || {};
ol.format.filter.ResourceId = $ol$format$filter$ResourceId || {};
ol.format.filter.Spatial = $ol$format$filter$Spatial || {};
ol.format.filter.Within = $ol$format$filter$Within || {};
ol.format.filter.and = _ol_format_filter$and || {};
ol.format.filter.bbox = _ol_format_filter$bbox || {};
ol.format.filter.between = _ol_format_filter$between || {};
ol.format.filter.contains = _ol_format_filter$contains || {};
ol.format.filter.disjoint = _ol_format_filter$disjoint || {};
ol.format.filter.during = _ol_format_filter$during || {};
ol.format.filter.dwithin = _ol_format_filter$dwithin || {};
ol.format.filter.equalTo = _ol_format_filter$equalTo || {};
ol.format.filter.greaterThan = _ol_format_filter$greaterThan || {};
ol.format.filter.greaterThanOrEqualTo = _ol_format_filter$greaterThanOrEqualTo || {};
ol.format.filter.intersects = _ol_format_filter$intersects || {};
ol.format.filter.isNull = _ol_format_filter$isNull || {};
ol.format.filter.lessThan = _ol_format_filter$lessThan || {};
ol.format.filter.lessThanOrEqualTo = _ol_format_filter$lessThanOrEqualTo || {};
ol.format.filter.like = _ol_format_filter$like || {};
ol.format.filter.not = _ol_format_filter$not || {};
ol.format.filter.notEqualTo = _ol_format_filter$notEqualTo || {};
ol.format.filter.or = _ol_format_filter$or || {};
ol.format.filter.resourceId = _ol_format_filter$resourceId || {};
ol.format.filter.within = _ol_format_filter$within || {};
ol.format.xsd = {};
ol.format.xsd.readBoolean = _ol_format_xsd$readBoolean || {};
ol.format.xsd.readBooleanString = _ol_format_xsd$readBooleanString || {};
ol.format.xsd.readDateTime = _ol_format_xsd$readDateTime || {};
ol.format.xsd.readDecimal = _ol_format_xsd$readDecimal || {};
ol.format.xsd.readDecimalString = _ol_format_xsd$readDecimalString || {};
ol.format.xsd.readNonNegativeInteger = _ol_format_xsd$readNonNegativeInteger || {};
ol.format.xsd.readNonNegativeIntegerString = _ol_format_xsd$readNonNegativeIntegerString || {};
ol.format.xsd.readString = _ol_format_xsd$readString || {};
ol.format.xsd.writeBooleanTextNode = _ol_format_xsd$writeBooleanTextNode || {};
ol.format.xsd.writeCDATASection = _ol_format_xsd$writeCDATASection || {};
ol.format.xsd.writeDateTimeTextNode = _ol_format_xsd$writeDateTimeTextNode || {};
ol.format.xsd.writeDecimalTextNode = _ol_format_xsd$writeDecimalTextNode || {};
ol.format.xsd.writeNonNegativeIntegerTextNode = _ol_format_xsd$writeNonNegativeIntegerTextNode || {};
ol.format.xsd.writeStringTextNode = _ol_format_xsd$writeStringTextNode || {};
ol.functions = {};
ol.functions.FALSE = _ol_functions$FALSE || {};
ol.functions.TRUE = _ol_functions$TRUE || {};
ol.functions.VOID = _ol_functions$VOID || {};
ol.functions.memoizeOne = _ol_functions$memoizeOne || {};
ol.geom = {};
ol.geom.Circle = $ol$geom$Circle || {};
ol.geom.Geometry = $ol$geom$Geometry || {};
ol.geom.GeometryCollection = $ol$geom$GeometryCollection || {};
ol.geom.LineString = $ol$geom$LineString || {};
ol.geom.LinearRing = $ol$geom$LinearRing || {};
ol.geom.MultiLineString = $ol$geom$MultiLineString || {};
ol.geom.MultiPoint = $ol$geom$MultiPoint || {};
ol.geom.MultiPolygon = $ol$geom$MultiPolygon || {};
ol.geom.Point = $ol$geom$Point || {};
ol.geom.Polygon = $ol$geom$Polygon || {};
ol.geom.Polygon.circular = _ol_geom_Polygon$circular || {};
ol.geom.Polygon.fromCircle = _ol_geom_Polygon$fromCircle || {};
ol.geom.Polygon.fromExtent = _ol_geom_Polygon$fromExtent || {};
ol.geom.Polygon.makeRegular = _ol_geom_Polygon$makeRegular || {};
ol.geom.SimpleGeometry = $ol$geom$SimpleGeometry || {};
ol.geom.SimpleGeometry.getStrideForLayout = _ol_geom_SimpleGeometry$getStrideForLayout || {};
ol.geom.SimpleGeometry.transformGeom2D = _ol_geom_SimpleGeometry$transformGeom2D || {};
ol.geom.flat = {};
ol.geom.flat.area = {};
ol.geom.flat.area.linearRing = _ol_geom_flat_area$linearRing || {};
ol.geom.flat.area.linearRings = _ol_geom_flat_area$linearRings || {};
ol.geom.flat.area.linearRingss = _ol_geom_flat_area$linearRingss || {};
ol.geom.flat.center = {};
ol.geom.flat.center.linearRingss = _ol_geom_flat_center$linearRingss || {};
ol.geom.flat.closest = $ol$geom$flat$closest || {};
ol.geom.flat.closest.arrayMaxSquaredDelta = _ol_geom_flat_closest$arrayMaxSquaredDelta || {};
ol.geom.flat.closest.assignClosestArrayPoint = _ol_geom_flat_closest$assignClosestArrayPoint || {};
ol.geom.flat.closest.assignClosestMultiArrayPoint = _ol_geom_flat_closest$assignClosestMultiArrayPoint || {};
ol.geom.flat.closest.assignClosestPoint = _ol_geom_flat_closest$assignClosestPoint || {};
ol.geom.flat.closest.maxSquaredDelta = _ol_geom_flat_closest$maxSquaredDelta || {};
ol.geom.flat.closest.multiArrayMaxSquaredDelta = _ol_geom_flat_closest$multiArrayMaxSquaredDelta || {};
ol.geom.flat.contains = {};
ol.geom.flat.contains.linearRingContainsExtent = _ol_geom_flat_contains$linearRingContainsExtent || {};
ol.geom.flat.contains.linearRingContainsXY = _ol_geom_flat_contains$linearRingContainsXY || {};
ol.geom.flat.contains.linearRingsContainsXY = _ol_geom_flat_contains$linearRingsContainsXY || {};
ol.geom.flat.contains.linearRingssContainsXY = _ol_geom_flat_contains$linearRingssContainsXY || {};
ol.geom.flat.deflate = {};
ol.geom.flat.deflate.deflateCoordinate = _ol_geom_flat_deflate$deflateCoordinate || {};
ol.geom.flat.deflate.deflateCoordinates = _ol_geom_flat_deflate$deflateCoordinates || {};
ol.geom.flat.deflate.deflateCoordinatesArray = _ol_geom_flat_deflate$deflateCoordinatesArray || {};
ol.geom.flat.deflate.deflateMultiCoordinatesArray = _ol_geom_flat_deflate$deflateMultiCoordinatesArray || {};
ol.geom.flat.flip = {};
ol.geom.flat.flip.flipXY = _ol_geom_flat_flip$flipXY || {};
ol.geom.flat.geodesic = $ol$geom$flat$geodesic || {};
ol.geom.flat.geodesic.greatCircleArc = _ol_geom_flat_geodesic$greatCircleArc || {};
ol.geom.flat.geodesic.meridian = _ol_geom_flat_geodesic$meridian || {};
ol.geom.flat.geodesic.parallel = _ol_geom_flat_geodesic$parallel || {};
ol.geom.flat.inflate = {};
ol.geom.flat.inflate.inflateCoordinates = _ol_geom_flat_inflate$inflateCoordinates || {};
ol.geom.flat.inflate.inflateCoordinatesArray = _ol_geom_flat_inflate$inflateCoordinatesArray || {};
ol.geom.flat.inflate.inflateMultiCoordinatesArray = _ol_geom_flat_inflate$inflateMultiCoordinatesArray || {};
ol.geom.flat.interiorpoint = {};
ol.geom.flat.interiorpoint.getInteriorPointOfArray = _ol_geom_flat_interiorpoint$getInteriorPointOfArray || {};
ol.geom.flat.interiorpoint.getInteriorPointsOfMultiArray = _ol_geom_flat_interiorpoint$getInteriorPointsOfMultiArray || {};
ol.geom.flat.interpolate = {};
ol.geom.flat.interpolate.interpolatePoint = _ol_geom_flat_interpolate$interpolatePoint || {};
ol.geom.flat.interpolate.lineStringCoordinateAtM = _ol_geom_flat_interpolate$lineStringCoordinateAtM || {};
ol.geom.flat.interpolate.lineStringsCoordinateAtM = _ol_geom_flat_interpolate$lineStringsCoordinateAtM || {};
ol.geom.flat.intersectsextent = {};
ol.geom.flat.intersectsextent.intersectsLineString = _ol_geom_flat_intersectsextent$intersectsLineString || {};
ol.geom.flat.intersectsextent.intersectsLineStringArray = _ol_geom_flat_intersectsextent$intersectsLineStringArray || {};
ol.geom.flat.intersectsextent.intersectsLinearRing = _ol_geom_flat_intersectsextent$intersectsLinearRing || {};
ol.geom.flat.intersectsextent.intersectsLinearRingArray = _ol_geom_flat_intersectsextent$intersectsLinearRingArray || {};
ol.geom.flat.intersectsextent.intersectsLinearRingMultiArray = _ol_geom_flat_intersectsextent$intersectsLinearRingMultiArray || {};
ol.geom.flat.length = {};
ol.geom.flat.length.lineStringLength = _ol_geom_flat_length$lineStringLength || {};
ol.geom.flat.length.linearRingLength = _ol_geom_flat_length$linearRingLength || {};
ol.geom.flat.orient = {};
ol.geom.flat.orient.linearRingIsClockwise = _ol_geom_flat_orient$linearRingIsClockwise || {};
ol.geom.flat.orient.linearRingsAreOriented = _ol_geom_flat_orient$linearRingsAreOriented || {};
ol.geom.flat.orient.linearRingssAreOriented = _ol_geom_flat_orient$linearRingssAreOriented || {};
ol.geom.flat.orient.orientLinearRings = _ol_geom_flat_orient$orientLinearRings || {};
ol.geom.flat.orient.orientLinearRingsArray = _ol_geom_flat_orient$orientLinearRingsArray || {};
ol.geom.flat.reverse = {};
ol.geom.flat.reverse.coordinates = _ol_geom_flat_reverse$coordinates || {};
ol.geom.flat.segments = {};
ol.geom.flat.segments.forEach = _ol_geom_flat_segments$forEach || {};
ol.geom.flat.simplify = {};
ol.geom.flat.simplify.douglasPeucker = _ol_geom_flat_simplify$douglasPeucker || {};
ol.geom.flat.simplify.douglasPeuckerArray = _ol_geom_flat_simplify$douglasPeuckerArray || {};
ol.geom.flat.simplify.douglasPeuckerMultiArray = _ol_geom_flat_simplify$douglasPeuckerMultiArray || {};
ol.geom.flat.simplify.quantize = _ol_geom_flat_simplify$quantize || {};
ol.geom.flat.simplify.quantizeArray = _ol_geom_flat_simplify$quantizeArray || {};
ol.geom.flat.simplify.quantizeMultiArray = _ol_geom_flat_simplify$quantizeMultiArray || {};
ol.geom.flat.simplify.radialDistance = _ol_geom_flat_simplify$radialDistance || {};
ol.geom.flat.simplify.simplifyLineString = _ol_geom_flat_simplify$simplifyLineString || {};
ol.geom.flat.simplify.snap = _ol_geom_flat_simplify$snap || {};
ol.geom.flat.straightchunk = {};
ol.geom.flat.straightchunk.matchingChunk = _ol_geom_flat_straightchunk$matchingChunk || {};
ol.geom.flat.textpath = {};
ol.geom.flat.textpath.drawTextOnPath = _ol_geom_flat_textpath$drawTextOnPath || {};
ol.geom.flat.topology = {};
ol.geom.flat.topology.lineStringIsClosed = _ol_geom_flat_topology$lineStringIsClosed || {};
ol.geom.flat.transform = {};
ol.geom.flat.transform.rotate = _ol_geom_flat_transform$rotate || {};
ol.geom.flat.transform.scale = _ol_geom_flat_transform$scale || {};
ol.geom.flat.transform.transform2D = _ol_geom_flat_transform$transform2D || {};
ol.geom.flat.transform.translate = _ol_geom_flat_transform$translate || {};
ol.has = $ol$has || {};
ol.has.DEVICE_PIXEL_RATIO = _ol_has$DEVICE_PIXEL_RATIO || {};
ol.has.FIREFOX = _ol_has$FIREFOX || {};
ol.has.IMAGE_DECODE = _ol_has$IMAGE_DECODE || {};
ol.has.MAC = _ol_has$MAC || {};
ol.has.PASSIVE_EVENT_LISTENERS = _ol_has$PASSIVE_EVENT_LISTENERS || {};
ol.has.SAFARI = _ol_has$SAFARI || {};
ol.has.WEBKIT = _ol_has$WEBKIT || {};
ol.has.WORKER_OFFSCREEN_CANVAS = _ol_has$WORKER_OFFSCREEN_CANVAS || {};
ol.interaction = {};
ol.interaction.DoubleClickZoom = $ol$interaction$DoubleClickZoom || {};
ol.interaction.DragAndDrop = $ol$interaction$DragAndDrop || {};
ol.interaction.DragAndDrop.DragAndDropEvent = _ol_interaction_DragAndDrop$DragAndDropEvent || {};
ol.interaction.DragBox = $ol$interaction$DragBox || {};
ol.interaction.DragBox.DragBoxEvent = _ol_interaction_DragBox$DragBoxEvent || {};
ol.interaction.DragPan = $ol$interaction$DragPan || {};
ol.interaction.DragRotate = $ol$interaction$DragRotate || {};
ol.interaction.DragRotateAndZoom = $ol$interaction$DragRotateAndZoom || {};
ol.interaction.DragZoom = $ol$interaction$DragZoom || {};
ol.interaction.Draw = $ol$interaction$Draw || {};
ol.interaction.Draw.DrawEvent = _ol_interaction_Draw$DrawEvent || {};
ol.interaction.Draw.createBox = _ol_interaction_Draw$createBox || {};
ol.interaction.Draw.createRegularPolygon = _ol_interaction_Draw$createRegularPolygon || {};
ol.interaction.Extent = $ol$interaction$Extent || {};
ol.interaction.Extent.ExtentEvent = _ol_interaction_Extent$ExtentEvent || {};
ol.interaction.Interaction = $ol$interaction$Interaction || {};
ol.interaction.Interaction.pan = _ol_interaction_Interaction$pan || {};
ol.interaction.Interaction.zoomByDelta = _ol_interaction_Interaction$zoomByDelta || {};
ol.interaction.KeyboardPan = $ol$interaction$KeyboardPan || {};
ol.interaction.KeyboardZoom = $ol$interaction$KeyboardZoom || {};
ol.interaction.Modify = $ol$interaction$Modify || {};
ol.interaction.Modify.ModifyEvent = _ol_interaction_Modify$ModifyEvent || {};
ol.interaction.MouseWheelZoom = $ol$interaction$MouseWheelZoom || {};
ol.interaction.PinchRotate = $ol$interaction$PinchRotate || {};
ol.interaction.PinchZoom = $ol$interaction$PinchZoom || {};
ol.interaction.Pointer = $ol$interaction$Pointer || {};
ol.interaction.Pointer.centroid = _ol_interaction_Pointer$centroid || {};
ol.interaction.Select = $ol$interaction$Select || {};
ol.interaction.Select.SelectEvent = _ol_interaction_Select$SelectEvent || {};
ol.interaction.Snap = $ol$interaction$Snap || {};
ol.interaction.Translate = $ol$interaction$Translate || {};
ol.interaction.Translate.TranslateEvent = _ol_interaction_Translate$TranslateEvent || {};
ol.interaction.defaults = _ol_interaction$defaults || {};
ol.layer = {};
ol.layer.Base = $ol$layer$Base || {};
ol.layer.BaseImage = $ol$layer$BaseImage || {};
ol.layer.BaseTile = $ol$layer$BaseTile || {};
ol.layer.BaseVector = $ol$layer$BaseVector || {};
ol.layer.Graticule = $ol$layer$Graticule || {};
ol.layer.Group = $ol$layer$Group || {};
ol.layer.Heatmap = $ol$layer$Heatmap || {};
ol.layer.Image = $ol$layer$Image || {};
ol.layer.Layer = $ol$layer$Layer || {};
ol.layer.Layer.inView = _ol_layer_Layer$inView || {};
ol.layer.MapboxVector = $ol$layer$MapboxVector || {};
ol.layer.MapboxVector.getMapboxPath = _ol_layer_MapboxVector$getMapboxPath || {};
ol.layer.MapboxVector.normalizeGlyphsUrl = _ol_layer_MapboxVector$normalizeGlyphsUrl || {};
ol.layer.MapboxVector.normalizeSourceUrl = _ol_layer_MapboxVector$normalizeSourceUrl || {};
ol.layer.MapboxVector.normalizeSpriteUrl = _ol_layer_MapboxVector$normalizeSpriteUrl || {};
ol.layer.MapboxVector.normalizeStyleUrl = _ol_layer_MapboxVector$normalizeStyleUrl || {};
ol.layer.Tile = $ol$layer$Tile || {};
ol.layer.Vector = $ol$layer$Vector || {};
ol.layer.VectorImage = $ol$layer$VectorImage || {};
ol.layer.VectorTile = $ol$layer$VectorTile || {};
ol.layer.WebGLPoints = $ol$layer$WebGLPoints || {};
ol.loadingstrategy = {};
ol.loadingstrategy.all = _ol_loadingstrategy$all || {};
ol.loadingstrategy.bbox = _ol_loadingstrategy$bbox || {};
ol.loadingstrategy.tile = _ol_loadingstrategy$tile || {};
ol.math = $ol$math || {};
ol.math.clamp = _ol_math$clamp || {};
ol.math.cosh = _ol_math$cosh || {};
ol.math.lerp = _ol_math$lerp || {};
ol.math.log2 = _ol_math$log2 || {};
ol.math.modulo = _ol_math$modulo || {};
ol.math.solveLinearSystem = _ol_math$solveLinearSystem || {};
ol.math.squaredDistance = _ol_math$squaredDistance || {};
ol.math.squaredSegmentDistance = _ol_math$squaredSegmentDistance || {};
ol.math.toDegrees = _ol_math$toDegrees || {};
ol.math.toRadians = _ol_math$toRadians || {};
ol.net = $ol$net || {};
ol.net.jsonp = _ol_net$jsonp || {};
ol.obj = $ol$obj || {};
ol.obj.assign = _ol_obj$assign || {};
ol.obj.clear = _ol_obj$clear || {};
ol.obj.getValues = _ol_obj$getValues || {};
ol.obj.isEmpty = _ol_obj$isEmpty || {};
ol.proj = {};
ol.proj.Projection = $ol$proj$Projection || {};
ol.proj.Units = $ol$proj$Units || {};
ol.proj.Units.METERS_PER_UNIT = _ol_proj_Units$METERS_PER_UNIT || {};
ol.proj.addCommon = _ol_proj$addCommon || {};
ol.proj.addCoordinateTransforms = _ol_proj$addCoordinateTransforms || {};
ol.proj.addEquivalentProjections = _ol_proj$addEquivalentProjections || {};
ol.proj.addEquivalentTransforms = _ol_proj$addEquivalentTransforms || {};
ol.proj.addProjection = _ol_proj$addProjection || {};
ol.proj.addProjections = _ol_proj$addProjections || {};
ol.proj.clearAllProjections = _ol_proj$clearAllProjections || {};
ol.proj.clearUserProjection = _ol_proj$clearUserProjection || {};
ol.proj.cloneTransform = _ol_proj$cloneTransform || {};
ol.proj.createProjection = _ol_proj$createProjection || {};
ol.proj.createSafeCoordinateTransform = _ol_proj$createSafeCoordinateTransform || {};
ol.proj.createTransformFromCoordinateTransform = _ol_proj$createTransformFromCoordinateTransform || {};
ol.proj.epsg3857 = $ol$proj$epsg3857 || {};
ol.proj.epsg3857.EXTENT = _ol_proj_epsg3857$EXTENT || {};
ol.proj.epsg3857.HALF_SIZE = _ol_proj_epsg3857$HALF_SIZE || {};
ol.proj.epsg3857.MAX_SAFE_Y = _ol_proj_epsg3857$MAX_SAFE_Y || {};
ol.proj.epsg3857.PROJECTIONS = _ol_proj_epsg3857$PROJECTIONS || {};
ol.proj.epsg3857.RADIUS = _ol_proj_epsg3857$RADIUS || {};
ol.proj.epsg3857.WORLD_EXTENT = _ol_proj_epsg3857$WORLD_EXTENT || {};
ol.proj.epsg3857.fromEPSG4326 = _ol_proj_epsg3857$fromEPSG4326 || {};
ol.proj.epsg3857.toEPSG4326 = _ol_proj_epsg3857$toEPSG4326 || {};
ol.proj.epsg4326 = $ol$proj$epsg4326 || {};
ol.proj.epsg4326.EXTENT = _ol_proj_epsg4326$EXTENT || {};
ol.proj.epsg4326.METERS_PER_UNIT = _ol_proj_epsg4326$METERS_PER_UNIT || {};
ol.proj.epsg4326.PROJECTIONS = _ol_proj_epsg4326$PROJECTIONS || {};
ol.proj.epsg4326.RADIUS = _ol_proj_epsg4326$RADIUS || {};
ol.proj.equivalent = _ol_proj$equivalent || {};
ol.proj.fromLonLat = _ol_proj$fromLonLat || {};
ol.proj.fromUserCoordinate = _ol_proj$fromUserCoordinate || {};
ol.proj.fromUserExtent = _ol_proj$fromUserExtent || {};
ol.proj.get = _ol_proj$get || {};
ol.proj.getPointResolution = _ol_proj$getPointResolution || {};
ol.proj.getTransform = _ol_proj$getTransform || {};
ol.proj.getTransformFromProjections = _ol_proj$getTransformFromProjections || {};
ol.proj.getUserProjection = _ol_proj$getUserProjection || {};
ol.proj.identityTransform = _ol_proj$identityTransform || {};
ol.proj.proj4 = {};
ol.proj.proj4.register = _ol_proj_proj4$register || {};
ol.proj.projections = {};
ol.proj.projections.add = _ol_proj_projections$add || {};
ol.proj.projections.clear = _ol_proj_projections$clear || {};
ol.proj.projections.get = _ol_proj_projections$get || {};
ol.proj.setUserProjection = _ol_proj$setUserProjection || {};
ol.proj.toLonLat = _ol_proj$toLonLat || {};
ol.proj.toUserCoordinate = _ol_proj$toUserCoordinate || {};
ol.proj.toUserExtent = _ol_proj$toUserExtent || {};
ol.proj.transform = _ol_proj$transform || {};
ol.proj.transformExtent = _ol_proj$transformExtent || {};
ol.proj.transformWithProjections = _ol_proj$transformWithProjections || {};
ol.proj.transforms = {};
ol.proj.transforms.add = _ol_proj_transforms$add || {};
ol.proj.transforms.clear = _ol_proj_transforms$clear || {};
ol.proj.transforms.get = _ol_proj_transforms$get || {};
ol.proj.transforms.remove = _ol_proj_transforms$remove || {};
ol.proj.useGeographic = _ol_proj$useGeographic || {};
ol.render = {};
ol.render.Box = $ol$render$Box || {};
ol.render.Event = $ol$render$Event || {};
ol.render.Feature = $ol$render$Feature || {};
ol.render.VectorContext = $ol$render$VectorContext || {};
ol.render.canvas = $ol$render$canvas || {};
ol.render.canvas.Builder = $ol$render$canvas$Builder || {};
ol.render.canvas.BuilderGroup = $ol$render$canvas$BuilderGroup || {};
ol.render.canvas.Executor = $ol$render$canvas$Executor || {};
ol.render.canvas.ExecutorGroup = $ol$render$canvas$ExecutorGroup || {};
ol.render.canvas.ExecutorGroup.getPixelIndexArray = _ol_render_canvas_ExecutorGroup$getPixelIndexArray || {};
ol.render.canvas.ImageBuilder = $ol$render$canvas$ImageBuilder || {};
ol.render.canvas.Immediate = $ol$render$canvas$Immediate || {};
ol.render.canvas.Instruction = $ol$render$canvas$Instruction || {};
ol.render.canvas.Instruction.beginPathInstruction = _ol_render_canvas_Instruction$beginPathInstruction || {};
ol.render.canvas.Instruction.closePathInstruction = _ol_render_canvas_Instruction$closePathInstruction || {};
ol.render.canvas.Instruction.fillInstruction = _ol_render_canvas_Instruction$fillInstruction || {};
ol.render.canvas.Instruction.strokeInstruction = _ol_render_canvas_Instruction$strokeInstruction || {};
ol.render.canvas.LineStringBuilder = $ol$render$canvas$LineStringBuilder || {};
ol.render.canvas.PolygonBuilder = $ol$render$canvas$PolygonBuilder || {};
ol.render.canvas.TextBuilder = $ol$render$canvas$TextBuilder || {};
ol.render.canvas.checkedFonts = _ol_render_canvas$checkedFonts || {};
ol.render.canvas.createTransformString = _ol_render_canvas$createTransformString || {};
ol.render.canvas.defaultFillStyle = _ol_render_canvas$defaultFillStyle || {};
ol.render.canvas.defaultFont = _ol_render_canvas$defaultFont || {};
ol.render.canvas.defaultLineCap = _ol_render_canvas$defaultLineCap || {};
ol.render.canvas.defaultLineDash = _ol_render_canvas$defaultLineDash || {};
ol.render.canvas.defaultLineDashOffset = _ol_render_canvas$defaultLineDashOffset || {};
ol.render.canvas.defaultLineJoin = _ol_render_canvas$defaultLineJoin || {};
ol.render.canvas.defaultLineWidth = _ol_render_canvas$defaultLineWidth || {};
ol.render.canvas.defaultMiterLimit = _ol_render_canvas$defaultMiterLimit || {};
ol.render.canvas.defaultPadding = _ol_render_canvas$defaultPadding || {};
ol.render.canvas.defaultStrokeStyle = _ol_render_canvas$defaultStrokeStyle || {};
ol.render.canvas.defaultTextAlign = _ol_render_canvas$defaultTextAlign || {};
ol.render.canvas.defaultTextBaseline = _ol_render_canvas$defaultTextBaseline || {};
ol.render.canvas.drawImageOrLabel = _ol_render_canvas$drawImageOrLabel || {};
ol.render.canvas.hitdetect = {};
ol.render.canvas.hitdetect.createHitDetectionImageData = _ol_render_canvas_hitdetect$createHitDetectionImageData || {};
ol.render.canvas.hitdetect.hitDetect = _ol_render_canvas_hitdetect$hitDetect || {};
ol.render.canvas.labelCache = _ol_render_canvas$labelCache || {};
ol.render.canvas.measureAndCacheTextWidth = _ol_render_canvas$measureAndCacheTextWidth || {};
ol.render.canvas.measureTextHeight = _ol_render_canvas$measureTextHeight || {};
ol.render.canvas.measureTextWidth = _ol_render_canvas$measureTextWidth || {};
ol.render.canvas.measureTextWidths = _ol_render_canvas$measureTextWidths || {};
ol.render.canvas.registerFont = _ol_render_canvas$registerFont || {};
ol.render.canvas.rotateAtOffset = _ol_render_canvas$rotateAtOffset || {};
ol.render.canvas.textHeights = _ol_render_canvas$textHeights || {};
ol.render.getRenderPixel = _ol_render$getRenderPixel || {};
ol.render.getVectorContext = _ol_render$getVectorContext || {};
ol.render.toContext = _ol_render$toContext || {};
ol.renderer = {};
ol.renderer.Composite = $ol$renderer$Composite || {};
ol.renderer.Layer = $ol$renderer$Layer || {};
ol.renderer.Map = $ol$renderer$Map || {};
ol.renderer.canvas = {};
ol.renderer.canvas.ImageLayer = $ol$renderer$canvas$ImageLayer || {};
ol.renderer.canvas.Layer = $ol$renderer$canvas$Layer || {};
ol.renderer.canvas.TileLayer = $ol$renderer$canvas$TileLayer || {};
ol.renderer.canvas.VectorImageLayer = $ol$renderer$canvas$VectorImageLayer || {};
ol.renderer.canvas.VectorLayer = $ol$renderer$canvas$VectorLayer || {};
ol.renderer.canvas.VectorTileLayer = $ol$renderer$canvas$VectorTileLayer || {};
ol.renderer.vector = $ol$renderer$vector || {};
ol.renderer.vector.defaultOrder = _ol_renderer_vector$defaultOrder || {};
ol.renderer.vector.getSquaredTolerance = _ol_renderer_vector$getSquaredTolerance || {};
ol.renderer.vector.getTolerance = _ol_renderer_vector$getTolerance || {};
ol.renderer.vector.renderFeature = _ol_renderer_vector$renderFeature || {};
ol.renderer.webgl = {};
ol.renderer.webgl.Layer = $ol$renderer$webgl$Layer || {};
ol.renderer.webgl.Layer.colorDecodeId = _ol_renderer_webgl_Layer$colorDecodeId || {};
ol.renderer.webgl.Layer.colorEncodeId = _ol_renderer_webgl_Layer$colorEncodeId || {};
ol.renderer.webgl.Layer.getBlankImageData = _ol_renderer_webgl_Layer$getBlankImageData || {};
ol.renderer.webgl.Layer.writePointFeatureToBuffers = _ol_renderer_webgl_Layer$writePointFeatureToBuffers || {};
ol.renderer.webgl.PointsLayer = $ol$renderer$webgl$PointsLayer || {};
ol.reproj = $ol$reproj || {};
ol.reproj.Image = $ol$reproj$Image || {};
ol.reproj.Tile = $ol$reproj$Tile || {};
ol.reproj.Triangulation = $ol$reproj$Triangulation || {};
ol.reproj.calculateSourceExtentResolution = _ol_reproj$calculateSourceExtentResolution || {};
ol.reproj.calculateSourceResolution = _ol_reproj$calculateSourceResolution || {};
ol.reproj.common = $ol$reproj$common || {};
ol.reproj.common.ENABLE_RASTER_REPROJECTION = _ol_reproj_common$ENABLE_RASTER_REPROJECTION || {};
ol.reproj.common.ERROR_THRESHOLD = _ol_reproj_common$ERROR_THRESHOLD || {};
ol.reproj.render = _ol_reproj$render || {};
ol.resolutionconstraint = $ol$resolutionconstraint || {};
ol.resolutionconstraint.createMinMaxResolution = _ol_resolutionconstraint$createMinMaxResolution || {};
ol.resolutionconstraint.createSnapToPower = _ol_resolutionconstraint$createSnapToPower || {};
ol.resolutionconstraint.createSnapToResolutions = _ol_resolutionconstraint$createSnapToResolutions || {};
ol.rotationconstraint = {};
ol.rotationconstraint.createSnapToN = _ol_rotationconstraint$createSnapToN || {};
ol.rotationconstraint.createSnapToZero = _ol_rotationconstraint$createSnapToZero || {};
ol.rotationconstraint.disable = _ol_rotationconstraint$disable || {};
ol.rotationconstraint.none = _ol_rotationconstraint$none || {};
ol.size = {};
ol.size.buffer = _ol_size$buffer || {};
ol.size.hasArea = _ol_size$hasArea || {};
ol.size.scale = _ol_size$scale || {};
ol.size.toSize = _ol_size$toSize || {};
ol.source = {};
ol.source.BingMaps = $ol$source$BingMaps || {};
ol.source.BingMaps.quadKey = _ol_source_BingMaps$quadKey || {};
ol.source.CartoDB = $ol$source$CartoDB || {};
ol.source.Cluster = $ol$source$Cluster || {};
ol.source.IIIF = $ol$source$IIIF || {};
ol.source.Image = $ol$source$Image || {};
ol.source.Image.ImageSourceEvent = _ol_source_Image$ImageSourceEvent || {};
ol.source.Image.defaultImageLoadFunction = _ol_source_Image$defaultImageLoadFunction || {};
ol.source.ImageArcGISRest = $ol$source$ImageArcGISRest || {};
ol.source.ImageCanvas = $ol$source$ImageCanvas || {};
ol.source.ImageMapGuide = $ol$source$ImageMapGuide || {};
ol.source.ImageStatic = $ol$source$ImageStatic || {};
ol.source.ImageWMS = $ol$source$ImageWMS || {};
ol.source.OSM = $ol$source$OSM || {};
ol.source.OSM.ATTRIBUTION = _ol_source_OSM$ATTRIBUTION || {};
ol.source.Raster = $ol$source$Raster || {};
ol.source.Raster.Processor = _ol_source_Raster$Processor || {};
ol.source.Raster.RasterSourceEvent = _ol_source_Raster$RasterSourceEvent || {};
ol.source.Raster.newImageData = _ol_source_Raster$newImageData || {};
ol.source.Source = $ol$source$Source || {};
ol.source.Stamen = $ol$source$Stamen || {};
ol.source.Tile = $ol$source$Tile || {};
ol.source.Tile.TileSourceEvent = _ol_source_Tile$TileSourceEvent || {};
ol.source.TileArcGISRest = $ol$source$TileArcGISRest || {};
ol.source.TileDebug = $ol$source$TileDebug || {};
ol.source.TileImage = $ol$source$TileImage || {};
ol.source.TileJSON = $ol$source$TileJSON || {};
ol.source.TileWMS = $ol$source$TileWMS || {};
ol.source.UTFGrid = $ol$source$UTFGrid || {};
ol.source.UTFGrid.CustomTile = _ol_source_UTFGrid$CustomTile || {};
ol.source.UrlTile = $ol$source$UrlTile || {};
ol.source.Vector = $ol$source$Vector || {};
ol.source.Vector.VectorSourceEvent = _ol_source_Vector$VectorSourceEvent || {};
ol.source.VectorTile = $ol$source$VectorTile || {};
ol.source.VectorTile.defaultLoadFunction = _ol_source_VectorTile$defaultLoadFunction || {};
ol.source.WMTS = $ol$source$WMTS || {};
ol.source.WMTS.optionsFromCapabilities = _ol_source_WMTS$optionsFromCapabilities || {};
ol.source.XYZ = $ol$source$XYZ || {};
ol.source.Zoomify = $ol$source$Zoomify || {};
ol.source.Zoomify.CustomTile = _ol_source_Zoomify$CustomTile || {};
ol.source.common = $ol$source$common || {};
ol.source.common.DEFAULT_WMS_VERSION = _ol_source_common$DEFAULT_WMS_VERSION || {};
ol.source.common.IMAGE_SMOOTHING_DISABLED = _ol_source_common$IMAGE_SMOOTHING_DISABLED || {};
ol.sphere = $ol$sphere || {};
ol.sphere.DEFAULT_RADIUS = _ol_sphere$DEFAULT_RADIUS || {};
ol.sphere.getArea = _ol_sphere$getArea || {};
ol.sphere.getDistance = _ol_sphere$getDistance || {};
ol.sphere.getLength = _ol_sphere$getLength || {};
ol.sphere.offset = _ol_sphere$offset || {};
ol.string = {};
ol.string.compareVersions = _ol_string$compareVersions || {};
ol.string.padNumber = _ol_string$padNumber || {};
ol.structs = {};
ol.structs.LRUCache = $ol$structs$LRUCache || {};
ol.structs.LinkedList = $ol$structs$LinkedList || {};
ol.structs.PriorityQueue = $ol$structs$PriorityQueue || {};
ol.structs.PriorityQueue.DROP = _ol_structs_PriorityQueue$DROP || {};
ol.structs.RBush = $ol$structs$RBush || {};
ol.style = {};
ol.style.Circle = $ol$style$Circle || {};
ol.style.Fill = $ol$style$Fill || {};
ol.style.Icon = $ol$style$Icon || {};
ol.style.IconImage = $ol$style$IconImage || {};
ol.style.IconImage.get = _ol_style_IconImage$get || {};
ol.style.IconImageCache = $ol$style$IconImageCache || {};
ol.style.IconImageCache.shared = _ol_style_IconImageCache$shared || {};
ol.style.Image = $ol$style$Image || {};
ol.style.LiteralStyle = $ol$style$LiteralStyle || {};
ol.style.RegularShape = $ol$style$RegularShape || {};
ol.style.Stroke = $ol$style$Stroke || {};
ol.style.Style = $ol$style$Style || {};
ol.style.Style.createDefaultStyle = _ol_style_Style$createDefaultStyle || {};
ol.style.Style.createEditingStyle = _ol_style_Style$createEditingStyle || {};
ol.style.Style.toFunction = _ol_style_Style$toFunction || {};
ol.style.Text = $ol$style$Text || {};
ol.style.expressions = $ol$style$expressions || {};
ol.style.expressions.Operators = _ol_style_expressions$Operators || {};
ol.style.expressions.arrayToGlsl = _ol_style_expressions$arrayToGlsl || {};
ol.style.expressions.colorToGlsl = _ol_style_expressions$colorToGlsl || {};
ol.style.expressions.expressionToGlsl = _ol_style_expressions$expressionToGlsl || {};
ol.style.expressions.getStringNumberEquivalent = _ol_style_expressions$getStringNumberEquivalent || {};
ol.style.expressions.getValueType = _ol_style_expressions$getValueType || {};
ol.style.expressions.isTypeUnique = _ol_style_expressions$isTypeUnique || {};
ol.style.expressions.numberToGlsl = _ol_style_expressions$numberToGlsl || {};
ol.style.expressions.stringToGlsl = _ol_style_expressions$stringToGlsl || {};
ol.tilecoord = {};
ol.tilecoord.createOrUpdate = _ol_tilecoord$createOrUpdate || {};
ol.tilecoord.fromKey = _ol_tilecoord$fromKey || {};
ol.tilecoord.getKey = _ol_tilecoord$getKey || {};
ol.tilecoord.getKeyZXY = _ol_tilecoord$getKeyZXY || {};
ol.tilecoord.hash = _ol_tilecoord$hash || {};
ol.tilecoord.withinExtentAndZ = _ol_tilecoord$withinExtentAndZ || {};
ol.tilegrid = $ol$tilegrid || {};
ol.tilegrid.TileGrid = $ol$tilegrid$TileGrid || {};
ol.tilegrid.WMTS = $ol$tilegrid$WMTS || {};
ol.tilegrid.WMTS.createFromCapabilitiesMatrixSet = _ol_tilegrid_WMTS$createFromCapabilitiesMatrixSet || {};
ol.tilegrid.common = $ol$tilegrid$common || {};
ol.tilegrid.common.DEFAULT_MAX_ZOOM = _ol_tilegrid_common$DEFAULT_MAX_ZOOM || {};
ol.tilegrid.common.DEFAULT_TILE_SIZE = _ol_tilegrid_common$DEFAULT_TILE_SIZE || {};
ol.tilegrid.createForExtent = _ol_tilegrid$createForExtent || {};
ol.tilegrid.createForProjection = _ol_tilegrid$createForProjection || {};
ol.tilegrid.createXYZ = _ol_tilegrid$createXYZ || {};
ol.tilegrid.extentFromProjection = _ol_tilegrid$extentFromProjection || {};
ol.tilegrid.getForProjection = _ol_tilegrid$getForProjection || {};
ol.tilegrid.wrapX = _ol_tilegrid$wrapX || {};
ol.tileurlfunction = {};
ol.tileurlfunction.createFromTemplate = _ol_tileurlfunction$createFromTemplate || {};
ol.tileurlfunction.createFromTemplates = _ol_tileurlfunction$createFromTemplates || {};
ol.tileurlfunction.createFromTileUrlFunctions = _ol_tileurlfunction$createFromTileUrlFunctions || {};
ol.tileurlfunction.expandUrl = _ol_tileurlfunction$expandUrl || {};
ol.tileurlfunction.nullTileUrlFunction = _ol_tileurlfunction$nullTileUrlFunction || {};
ol.transform = $ol$transform || {};
ol.transform.apply = _ol_transform$apply || {};
ol.transform.compose = _ol_transform$compose || {};
ol.transform.composeCssTransform = _ol_transform$composeCssTransform || {};
ol.transform.create = _ol_transform$create || {};
ol.transform.determinant = _ol_transform$determinant || {};
ol.transform.invert = _ol_transform$invert || {};
ol.transform.makeInverse = _ol_transform$makeInverse || {};
ol.transform.makeScale = _ol_transform$makeScale || {};
ol.transform.multiply = _ol_transform$multiply || {};
ol.transform.reset = _ol_transform$reset || {};
ol.transform.rotate = _ol_transform$rotate || {};
ol.transform.scale = _ol_transform$scale || {};
ol.transform.set = _ol_transform$set || {};
ol.transform.setFromArray = _ol_transform$setFromArray || {};
ol.transform.toString = _ol_transform$toString || {};
ol.transform.translate = _ol_transform$translate || {};
ol.uri = {};
ol.uri.appendParams = _ol_uri$appendParams || {};
ol.util = $ol$util || {};
ol.util.VERSION = _ol_util$VERSION || {};
ol.util.abstract = _ol_util$abstract || {};
ol.util.getUid = _ol_util$getUid || {};
ol.vec = {};
ol.vec.mat4 = {};
ol.vec.mat4.create = _ol_vec_mat4$create || {};
ol.vec.mat4.fromTransform = _ol_vec_mat4$fromTransform || {};
ol.webgl = $ol$webgl || {};
ol.webgl.ARRAY_BUFFER = _ol_webgl$ARRAY_BUFFER || {};
ol.webgl.Buffer = $ol$webgl$Buffer || {};
ol.webgl.Buffer.getArrayClassForType = _ol_webgl_Buffer$getArrayClassForType || {};
ol.webgl.DYNAMIC_DRAW = _ol_webgl$DYNAMIC_DRAW || {};
ol.webgl.ELEMENT_ARRAY_BUFFER = _ol_webgl$ELEMENT_ARRAY_BUFFER || {};
ol.webgl.FLOAT = _ol_webgl$FLOAT || {};
ol.webgl.Helper = $ol$webgl$Helper || {};
ol.webgl.Helper.computeAttributesStride = _ol_webgl_Helper$computeAttributesStride || {};
ol.webgl.PostProcessingPass = $ol$webgl$PostProcessingPass || {};
ol.webgl.RenderTarget = $ol$webgl$RenderTarget || {};
ol.webgl.STATIC_DRAW = _ol_webgl$STATIC_DRAW || {};
ol.webgl.STREAM_DRAW = _ol_webgl$STREAM_DRAW || {};
ol.webgl.ShaderBuilder = $ol$webgl$ShaderBuilder || {};
ol.webgl.ShaderBuilder.ShaderBuilder = _ol_webgl_ShaderBuilder$ShaderBuilder || {};
ol.webgl.ShaderBuilder.parseLiteralStyle = _ol_webgl_ShaderBuilder$parseLiteralStyle || {};
ol.webgl.UNSIGNED_BYTE = _ol_webgl$UNSIGNED_BYTE || {};
ol.webgl.UNSIGNED_INT = _ol_webgl$UNSIGNED_INT || {};
ol.webgl.UNSIGNED_SHORT = _ol_webgl$UNSIGNED_SHORT || {};
ol.webgl.getContext = _ol_webgl$getContext || {};
ol.webgl.getSupportedExtensions = _ol_webgl$getSupportedExtensions || {};
ol.worker = {};
ol.worker.version = $ol$worker$version || {};
ol.worker.webgl = $ol$worker$webgl || {};
ol.xml = $ol$xml || {};
ol.xml.OBJECT_PROPERTY_NODE_FACTORY = _ol_xml$OBJECT_PROPERTY_NODE_FACTORY || {};
ol.xml.XML_SCHEMA_INSTANCE_URI = _ol_xml$XML_SCHEMA_INSTANCE_URI || {};
ol.xml.createElementNS = _ol_xml$createElementNS || {};
ol.xml.getAllTextContent = _ol_xml$getAllTextContent || {};
ol.xml.getAllTextContent_ = _ol_xml$getAllTextContent_ || {};
ol.xml.getAttributeNS = _ol_xml$getAttributeNS || {};
ol.xml.getDocument = _ol_xml$getDocument || {};
ol.xml.getXMLSerializer = _ol_xml$getXMLSerializer || {};
ol.xml.isDocument = _ol_xml$isDocument || {};
ol.xml.makeArrayExtender = _ol_xml$makeArrayExtender || {};
ol.xml.makeArrayPusher = _ol_xml$makeArrayPusher || {};
ol.xml.makeArraySerializer = _ol_xml$makeArraySerializer || {};
ol.xml.makeChildAppender = _ol_xml$makeChildAppender || {};
ol.xml.makeObjectPropertyPusher = _ol_xml$makeObjectPropertyPusher || {};
ol.xml.makeObjectPropertySetter = _ol_xml$makeObjectPropertySetter || {};
ol.xml.makeReplacer = _ol_xml$makeReplacer || {};
ol.xml.makeSequence = _ol_xml$makeSequence || {};
ol.xml.makeSimpleNodeFactory = _ol_xml$makeSimpleNodeFactory || {};
ol.xml.makeStructureNS = _ol_xml$makeStructureNS || {};
ol.xml.parse = _ol_xml$parse || {};
ol.xml.parseNode = _ol_xml$parseNode || {};
ol.xml.pushParseAndPop = _ol_xml$pushParseAndPop || {};
ol.xml.pushSerializeAndPop = _ol_xml$pushSerializeAndPop || {};
ol.xml.registerDocument = _ol_xml$registerDocument || {};
ol.xml.registerXMLSerializer = _ol_xml$registerXMLSerializer || {};
ol.xml.serialize = _ol_xml$serialize || {};

export default ol;